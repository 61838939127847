import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';

const useAnalytics = pageTitle => {
  const [cookies] = useCookies(['user']);
  useEffect(() => {
    if (cookies.diligent_ga === 'true')
      ReactGA.send({ hitType: 'pageview', page: pageTitle });
  }, []);
};

export default useAnalytics;
