import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function MyDropzone(props) {
  const [file, setFile] = useState('');
  const [empty, setEmpty] = useState(true);

  function emptyFiles() {
    const newFile = [];
    setFile(newFile);
  }

  const PDFsvg = (
    <svg
      className="mx-auto h-12 w-12"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 6.5V6H1V6.5H1.5ZM5.5 6.5V6H5V6.5H5.5ZM5.5 10.5H5V11H5.5V10.5ZM12.5 3.5H13V3.29289L12.8536 3.14645L12.5 3.5ZM9.5 0.5L9.85355 0.146447L9.70711 0H9.5V0.5ZM1.5 7H2.5V6H1.5V7ZM2 11V8.5H1V11H2ZM2 8.5V6.5H1V8.5H2ZM2.5 8H1.5V9H2.5V8ZM3 7.5C3 7.77614 2.77614 8 2.5 8V9C3.32843 9 4 8.32843 4 7.5H3ZM2.5 7C2.77614 7 3 7.22386 3 7.5H4C4 6.67157 3.32843 6 2.5 6V7ZM5 6.5V10.5H6V6.5H5ZM5.5 11H6.5V10H5.5V11ZM8 9.5V7.5H7V9.5H8ZM6.5 6H5.5V7H6.5V6ZM8 7.5C8 6.67157 7.32843 6 6.5 6V7C6.77614 7 7 7.22386 7 7.5H8ZM6.5 11C7.32843 11 8 10.3284 8 9.5H7C7 9.77614 6.77614 10 6.5 10V11ZM9 6V11H10V6H9ZM9.5 7H12V6H9.5V7ZM9.5 9H11V8H9.5V9ZM1 5V1.5H0V5H1ZM12 3.5V5H13V3.5H12ZM1.5 1H9.5V0H1.5V1ZM9.14645 0.853553L12.1464 3.85355L12.8536 3.14645L9.85355 0.146447L9.14645 0.853553ZM1 1.5C1 1.22386 1.22386 1 1.5 1V0C0.671573 0 0 0.671573 0 1.5H1ZM0 12V13.5H1V12H0ZM1.5 15H11.5V14H1.5V15ZM13 13.5V12H12V13.5H13ZM11.5 15C12.3284 15 13 14.3284 13 13.5H12C12 13.7761 11.7761 14 11.5 14V15ZM0 13.5C0 14.3284 0.671573 15 1.5 15V14C1.22386 14 1 13.7761 1 13.5H0Z"
        fill="#858585"
      />
    </svg>
  );

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (acceptedFiles[0] !== 'undefined' && acceptedFiles[0].type !== 'undefined') {
        if (acceptedFiles[0].type === 'application/pdf') {
          const files = Array.from(acceptedFiles);
          const fileBuffer = await files[0];
          setFile(fileBuffer);
          setEmpty(false);
        }
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    acceptedFiles: '.pdf',
    onDrop,
  });

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  // setFiles(prevState => {
  //   [...prevState, file];
  // });
  useEffect(() => {
    props.dropzoneToFormData(file);
    if (file === 0) {
      setEmpty(true);
    }
  }, [file]);

  return (
    <section className="container">
      <div className="flex flex-row w-full justify-between align-middle">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          CV
        </label>
        {/* {!empty && (
          <button type="button" onClick={() => emptyFiles()}>
            delete
          </button>
        )} */}
      </div>

      <div {...getRootProps({ className: 'dropzone' })}>
        <div className="mt-1 flex flex-row justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md min-h-dropzone">
          {empty ? (
            <div className="space-y-1 text-center flex flex-col justify-center items-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-dg-primary-600 hover:text-dg-primary-900 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    {...getInputProps()}
                    name="files"
                    type="file"
                    id="files"
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">
                PDF file up to 2MB (only 1 file can be submitted)
              </p>
            </div>
          ) : (
            <ul className="flex flex-row w-full justify-evenly">
              <li key={0}>
                {PDFsvg}
                <p>{truncate(file.name, 7)}</p>
              </li>
            </ul>
          )}
        </div>
        {/* <input {...getInputProps()} name="files" type="file"  multiple />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(2 files are the maximum number of files you can drop here)</em> */}
      </div>
    </section>
  );
}
