import React from 'react';
import { ReactComponent as BrakLine } from './../assets/BreakLine.svg';

const TimelineCard = ({ title, subtitle, paragraph, id }) => {
  const left = id % 2 === 0;

  return (
    <div
      className={
        'max-w-[450px] rounded-[32px] card-no-hover relative self-center' +
        (left ? ' lg:self-start' : ' lg:self-end')
      }
    >
      <div className="flex flex-col justify-start text-left gap-8p">
        <h4 className="subtitle-card-italic-gray">{subtitle}</h4>
        <h3 className="title-card capitalize text-dg-primary-900">{title}</h3>
        <BrakLine className="my-[28px]" />
        <p className="paragraph">{paragraph}</p>
      </div>
    </div>
  );
};

TimelineCard.propTypes = {};

export default TimelineCard;
