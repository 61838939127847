import { motion } from 'framer-motion';
import React from 'react';
import Wrapper from '../layout/Wrapper';
import OrbitOnScroll from './shared/graphics/OrbitOnScroll';
import PageHeading from './shared/PageHeading';
import WhyUsCard from './WhyUsCard';

const WhySection = ({ heading, cards, p1, p2 }) => {
  return (
    <Wrapper bg hideOverflow>
      <div className="relative">
        <div className="relative h-[1px] w-full m-auto">
          <div
            className="absolute hidden lg:block"
            id="test"
            style={{ top: '800px', marginLeft: '-200px' }}
          >
            <OrbitOnScroll />
          </div>
        </div>

        <motion.section
          id="why_us"
          className=" flex flex-col items-center relative pt-64p lg:pt-164p pb-[144px]"
        >
          <div className="flex flex-col justify-center items-center gap-90p">
            <div className="max-w-[780px]">
              <PageHeading
                subheading={heading.subtitle}
                heading={heading.title}
                paragraph1={p1}
                paragraph2={p2}
              />
            </div>

            <div className="flex flex-col lg:flex-row gap-32p h-fit sm:px-64p lg:px-0">
              {/* Card - left */}
              <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 90 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0, default: { duration: 0.45 } }}
              >
                <WhyUsCard
                  image={cards.Card1.Image.data.attributes.url}
                  alt={cards.Card1.Image.data.attributes.alternativeText}
                  heading={cards.Card1.Title}
                  paragraph={cards.Card1.paragraph}
                />
              </motion.div>
              {/* Card - mid */}
              <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 120 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0.27, default: { duration: 0.45 } }}
              >
                <WhyUsCard
                  image={cards.Card2.Image.data.attributes.url}
                  alt={cards.Card2.Image.data.attributes.alternativeText}
                  heading={cards.Card2.Title}
                  paragraph={cards.Card2.paragraph}
                />
              </motion.div>
              {/* Card - right */}
              <motion.div
                initial={{ opacity: 0, scale: 1.1, y: 140 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ delay: 0.54, default: { duration: 0.45 } }}
              >
                <WhyUsCard
                  image={cards.Card3.Image.data.attributes.url}
                  alt={cards.Card3.Image.data.attributes.alternativeText}
                  heading={cards.Card3.Title}
                  paragraph={cards.Card3.paragraph}
                />
              </motion.div>
            </div>
          </div>
        </motion.section>
      </div>
    </Wrapper>
  );
};

export default WhySection;
