import { motion, useScroll, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import '../../../styles/graphics.css';

export default function OrbitOnScroll() {
  const [currentPrecent, setCurrentPercent] = useState(null);
  const { scrollYProgress } = useScroll();

  const yRange = useTransform(scrollYProgress, [0, 1], [0, 100]);

  useEffect(
    () =>
      yRange.onChange(() => {
        setCurrentPercent(Math.trunc(yRange.current));
      }),
    [yRange],
  );

  return (
    <div className="relative w-full">
      <svg
        className="m-auto"
        //1541
        width="1541"
        //380
        height="380"
        viewBox="0 0 1541 380"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1514.94 209.543C1540.54 235.139 1544.54 259.128 1529.42 280.591C1514.3 302.049 1480.12 320.881 1429.75 336.15C1329.02 366.684 1163.8 382.894 957.933 377.503C752.066 372.113 544.665 346.145 375.809 308.551C291.373 289.753 216.675 268.069 156.673 244.644C96.6593 221.214 51.6101 196.15 26.0147 170.554C0.419309 144.959 -3.58562 120.969 11.5359 99.5073C26.6547 78.049 60.8366 59.2167 111.206 43.9482C211.934 13.414 377.157 -2.79642 583.023 2.59438C788.89 7.98519 996.291 33.9531 1165.15 71.5466C1249.58 90.3452 1324.28 112.029 1384.28 135.454C1444.3 158.884 1489.35 183.948 1514.94 209.543Z"
          stroke="#BBBBBB"
          strokeWidth="2"
        />
      </svg>

      <motion.div
        className="circle-scroll"
        //initial={{ offsetDistance: currentPrecentCircle }}
        //animate={{ offsetDistance: scrollYProgress }}
        style={{ offsetDistance: `${currentPrecent + 0}%` }}
      >
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.344 32.7837C27.1473 32.0173 33.6624 24.2595 32.896 15.4563C32.1296 6.65302 24.3719 0.137868 15.5686 0.90428C6.76536 1.67069 0.250206 9.42844 1.01662 18.2317C1.78303 27.035 9.54078 33.5501 18.344 32.7837Z"
            fill="#A1A3DD"
          />
        </svg>
      </motion.div>
      <motion.div
        className="triangle-scroll"
        //initial={{
        //  offsetDistance: currentPrecentTriangle,
        //  rotate: currentAngleTriangle,
        //}}
        //animate={{
        //  offsetDistance: scrollYProgress,
        //  rotate: currentAngleTriangle,
        //}}
        style={{
          offsetDistance: `${currentPrecent + 50}%`,
          rotate: currentPrecent * 10,
        }}
      >
        <svg
          width="51"
          height="49"
          viewBox="0 0 51 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.22398 6.55725C-0.561572 3.87137 1.4026 0.285162 4.62731 0.343418L46.5505 1.10082C49.3473 1.15134 51.2299 3.98344 50.1938 6.58172L34.6645 45.5267C33.4699 48.5223 29.4033 48.9453 27.6179 46.2596L1.22398 6.55725Z"
            fill="#FFA463"
          />
        </svg>
      </motion.div>
    </div>
  );
}
