import React from 'react';

const GradientWrapper = ({ children, padding }) => {
  return (
    <div
      className={
        'px-[16px] md:px-0 bg-gradient-to-r from-[#90278F] to-[#8468BF]'
      }
    >
      <div className={'max-w-custom md:text-center mx-auto flex flex-col gap-[16px]'  + (padding ? ` ${padding}` : null) }>{children}</div>
    </div>
  );
};

export default GradientWrapper;
