import { useEffect } from 'react';
import Animation_Diligent from '../assets/animation_diligent.webm';
import CardValues from '../components/shared/CardValues';
import HighlighedText from '../components/shared/HighlighedText';
import PageTitle from '../components/shared/PageTitle';
import ReactHelmet from '../components/shared/ReactHelmet';
import TimelineCard from '../components/TimelineCard';
import TimelineLogo from '../components/TimelineLogo';
import TimelineCardsWrapper2 from '../components/TimelineWrapper2';
import useAnalytics from '../hooks/useAnalytics';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import valuesBG from './../assets/ValuesBG.svg';
import useDataApi from './../hooks/useDataApi';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const strapiPopulate = [
  'Heading.Image',
  'Section.Image',
  'ItalicSection.Section.Image',
  'Mission.Image',
  'Vision.Image',
  'Values.Items',
  'Values.Items.Image',
  'Partners.images',
  'Indrustries',
  'Industries.Items',
  'Industries.Items.Image',
  'TimelineHeading',
  'Timeline.Steps',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

export default function About() {
  const strapi = strapiApiBuilder('aboutpage', strapiPopulate, '');

  const [{ data, isLoading }] = useDataApi(strapi);

  useAnalytics('About Us');

  useEffect(() => {
    document.title = 'About Us';
  }, []);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <PageLayout>
      {data && data.SEO && <ReactHelmet seo={data.SEO} />}
      <div className="bg-white dark:bg-dg-primary-1700 w-full pt-32">
        {/* Heading Section */}
        {data ? (
          <section
            id="heading"
            className="flex flex-col items-center justify-center max-w-custom m-auto w-full"
          >
            <Wrapper>
              <div className="flex flex-col md:flex-row gap-8p w-full pb-8">
                <div className="w-full md:w-1/2">
                  <PageTitle
                    heading={data.Heading.Title}
                    subheading={data.Heading.Subtitle}
                    left
                    color
                  />
                  <p className="mt-4 paragraph">{data.Heading.Paragraph}</p>
                </div>
              </div>
            </Wrapper>
          </section>
        ) : null}

        {data ? (
          <section
            id="sections"
            className="flex flex-col items-center justify-center max-w-custom m-auto w-full"
          >
            {data.Section &&
              data.Section.map((item, index) => (
                <div key={index}>
                  <Wrapper>
                    <div
                      className={
                        'flex flex-col gap-8 w-full items-center' +
                        (index % 2 == 0 ? ' md:flex-row-reverse' : ' md:flex-row')
                      }
                    >
                      <p className="mt-4 paragraph w-full md:w-1/2">{item.Paragraph}</p>
                      <img
                        src={item.Image.data.attributes.url}
                        alt="Our Team's image"
                        className="w-full md:w-1/2 text-center -ml-2 lg-ml-0"
                      />
                    </div>
                  </Wrapper>
                </div>
              ))}
          </section>
        ) : null}

        {data ? (
          <>
            <section
              id="highlight"
              className="flex flex-col items-center justify-center mt-16"
            >
              <HighlighedText text={data.ItalicSection.Italic} />
            </section>

            <section
              id="info"
              className="flex flex-col items-center justify-center mt-16"
            >
              <div className="flex flex-col items-center justify-center w-full">
                <img
                  src={data.ItalicSection.Section.Image.data.attributes.url}
                  alt={data.ItalicSection.Section.Image.data.attributes.alternativeText}
                  className="max-w-[660px] w-full"
                />
                <p className="text-sm font-medium italic text-gray-500 dark:text-white w-2/3 lg:w-1/3 mt-4">
                  {data.ItalicSection.Section.Paragraph}
                </p>
              </div>
            </section>
          </>
        ) : null}

        {/* Our Mission & Our Vision Section */}
        {data ? (
          <section
            id="mission_vision"
            className="bg-baby-blue dark:bg-dg-primary-1600 flex flex-col items-center justify-center mt-16"
          >
            <div className="my-8 flex flex-col md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
              <div className="w-full md:w-1/2 md:pr-16">
                <h2 className="heading text-dg-secondary">{data.OurMission}</h2>
                <p className="paragraph mt-4">{data.Mission.Paragraph}</p>
              </div>
              <img
                src={data.Mission.Image.data.attributes.url}
                alt={data.Mission.Image.data.attributes.alternativeText}
                className="text-center w-full md:w-1/2"
              />
            </div>
            <div className="my-8 flex flex-col-reverse md:flex-row justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
              <img
                src={data.Vision.Image.data.attributes.url}
                alt={data.Vision.Image.data.attributes.alternativeText}
                className="text-center w-full md:w-1/2 mt-8 md:mt-0"
              />
              <div className="w-full md:w-1/2 md:pl-16">
                <h2 className="heading text-dg-secondary">{data.OurVision}</h2>
                <p className="paragraph mt-4">{data.Vision.Paragraph}</p>
              </div>
            </div>
          </section>
        ) : null}

        {/* Values Section */}
        {data ? (
          <section
            id="values"
            className="bg-white dark:bg-dg-primary-1700 flex flex-row items-center justify-center mt-16"
          >
            <div className="my-8 flex flex-col justify-center items-start w-full max-w-custom m-auto px-8 xl:px-0">
              <h2 className="heading text-dg-secondary">{data.Values.Title}</h2>
              <div className="absolute justify-center md:flex hidden self-center">
                <img src={valuesBG} alt="Values background" />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-16 w-full mt-8">
                {data.Values.Items &&
                  data.Values.Items.map((item, index) => (
                    <CardValues
                      key={index}
                      image={item.Image.data.attributes.url}
                      alt={item.Image.data.attributes.alternativeText || 'alt'}
                      heading={item.heading}
                      paragraph={item.paragraph}
                    />
                  ))}
              </div>
            </div>
          </section>
        ) : null}

        {/* Partners Section */}
        {data ? (
          <section
            id="partners"
            className="bg-baby-blue dark:bg-dg-primary-1600 flex flex-col items-center justify-center mt-16"
          >
            <div className="my-16 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
              <div className="w-full md:w-2/3">
                <h2 className="text-center heading text-dg-secondary">
                  {data.Partners.heading}
                </h2>
                <p className="text-left paragraph mt-4">{data.Partners.paragraph}</p>
              </div>
              <div className="flex flex-row justify-between items-center w-full mt-16">
                {data.Partners.images.data &&
                  data.Partners.images.data.map((item, index) => (
                    <img
                      key={index}
                      src={item.attributes.url}
                      alt={item.attributes.alternativeText}
                      className="w-16 md:w-20 lg:w-24"
                    />
                  ))}
              </div>
            </div>
          </section>
        ) : null}

        {/* Industries Section */}
        {data ? (
          <section
            id="industries"
            className="bg-white dark:bg-dg-primary-1700 flex flex-col items-center justify-center mt-16"
          >
            <div className="my-8 flex flex-col justify-center items-center w-full max-w-custom m-auto px-8 xl:px-0">
              <div className="w-full">
                <h2 className="heading text-dg-secondary">{data.Industries.Title}</h2>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-32 w-full mt-8">
                {data.Industries &&
                  data.Industries.Items.map((industry, index) => (
                    <div key={index}>
                      <h3 className="h3-heading">{industry.heading}</h3>
                      <p className="paragraph mt-2">{industry.paragraph}</p>
                      {industry.Image.data && (
                        <img
                          src={industry.Image.data.attributes.url}
                          alt={industry.Image.data.attributes.alternativeText}
                          className="m-auto"
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </section>
        ) : null}

        {data ? (
          <Wrapper bg padding={' py-90p'}>
            <PageTitle
              heading={data.TimelineHeading.title}
              subheading={data.TimelineHeading.subtitle}
              left
            />

            <div className="my-90p">
              <div className="w-full relative ">
                <div className="md:absolute top-[5%] left-0 w-full h-full hidden md:block">
                  <TimelineLogo />
                </div>

                <TimelineCardsWrapper2>
                  {data.Timeline.Steps.map((item, index) => (
                    <TimelineCard
                      key={index}
                      id={index}
                      title={item.heading}
                      subtitle={item.subheading}
                      paragraph={item.paragraph}
                    />
                  ))}
                </TimelineCardsWrapper2>
              </div>
            </div>
          </Wrapper>
        ) : null}
      </div>
    </PageLayout>
  );
}
