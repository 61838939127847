import React from 'react';

const PortfolioTestimonial = ({ testimonial }) => {
  return (
    <div className="md:w-full flex md:flex-row flex-col bg-gradient-to-r from-[#90278F] to-[#8468BF] rounded-lg p-8">
      <div className="flex flex-col w-full md:w-1/3 p-4">
        <div className="flex mb-2">
          <img
            src={testimonial.client_image.data.attributes.url}
            alt={testimonial.title}
            className="w-[81px] h-[81px] rounded-full"
          />
          <div className="ml-4">
            <h3 className="font-[Poppins] text-[21px] font-semibold leading-[31.5px] text-left text-[#BDC4FF]">
              {testimonial.client_name}
            </h3>
            <p className="text-white">{testimonial.client_role}</p>
          </div>
        </div>
      </div>

      <div className="border-l border-[#BDC4FF] flex items-center justify-center w-full md:w-2/3 p-4 md:pl-4">
        <div className="font-[Poppins] text-[21px] italic font-medium leading-[31.5px] text-left text-[#F5F5F5]">
          "{testimonial.paragraph}"
        </div>
      </div>
    </div>
  );
};

export default PortfolioTestimonial;
