import React, { useRef } from 'react';
import { useEffect } from 'react';

import { useState } from 'react';
import UseAnimations from 'react-useanimations';
import volume from 'react-useanimations/lib/volume';

const api_url = process.env.REACT_APP_API_URL;

const VideoComponent = ({ source, alt }) => {
  const [mute, setMute] = useState(true);
  const [string, setString] = useState('');

  const vidRef = useRef();
  const muteRef = useRef();

  function toggleMute() {
    var video = vidRef.current;
    video.muted = !video.muted;
    setMute(!mute);
  }

  // this is needed becouse Strapi has a wierd bug that duplicates the folder path from Cloudflare,
  // this function checks the prop for dublicate folder path and removes one instance
  function handleString(str) {
    // if (str.indexOf('media-lib/') !== -1) {
    //   let afterMediaLib = str.substring(str.indexOf('media-lib/') + 'media-lib/'.length);

    //   if (
    //     afterMediaLib.indexOf('media-lib/') !== -1 &&
    //     afterMediaLib.substring(
    //       0,
    //       afterMediaLib.indexOf('media-lib/') + 'media-lib/'.length,
    //     ) === 'media-lib/'
    //   ) {
    //     str = str.replace('media-lib/test-bucket', '');
    //   }
    // }
    //hardcoded to this path
    str = str.replace('/media-lib/test-bucket', '');
    setString(str);
  }

  useEffect(() => {
    handleString(source);
  }, [source]);

  return (
    <div className="w-full py-48p lg:py-72p relative">
      {string != '' && (
        <video autoPlay loop ref={vidRef} muted className="rounded-[32px]">
          <source type="video/mp4" src={string} alt={alt} />
        </video>
      )}

      <div className="absolute bottom-16 right-4 md:bottom-24 md:right-8 rounded-full overflow-clip">
        <UseAnimations
          reverse={mute}
          onClick={() => {
            toggleMute();
          }}
          size={50}
          wrapperStyle={{ background: 'white', padding: '5px' }}
          animation={volume}
          speed={0.7}
        />
      </div>

      {/* <div className="">
        <button
          className={'unmuted toggle-sound' + (mute ? ' sound-mute' : null)}
          onClick={() => toggleMute()}
        >
          <div className="sound">
            <div className="sound--icon">
              <Speaker />
            </div>
            <div className="sound--wave sound--wave_one"></div>
            <div className="sound--wave sound--wave_two"></div>
            <div className="sound--wave sound--wave_three"></div>
          </div>
        </button>
      </div> */}
    </div>
  );
};

export default VideoComponent;
