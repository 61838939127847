import React from "react";

//to be deleted

export const ClientFormContext = React.createContext({
    tag: '',
    subject: '',
    firstName: '',
    lastName: '',
    email: '',
    description: '',
  });
  
export const JobFormContext = React.createContext({
    position: '',
    other: '',
    firstName: '',
    lastName: '',
    email: '',
    coverLetter: '',
    link:'',
    file: '',
});