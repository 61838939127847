import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import CustomBulletPoints from '../assets/icons/custom-bullet-point.svg';

export default function PricingCard(props) {
  const link = useNavigate();

  return (
    <div className="card-no-hover rounded-[16px] w-[325px] h-[600px] flex flex-col text-center justify-start p-8 border border-gray-300 shadow">
      <div>
        <h4 className="bg-clip-text text-transparent bg-gradient-to-r from-[#90278F] to-[#8468BF] font-[Poppins] text-[24px] font-semibold leading-[26.4px] tracking-[1px] text-left">
          {props.title}
        </h4>
        <p className="my-4 text-sm font-[Poppins] text-[14px] font-normal leading-[22px] text-left text-[#353535]">
          {props.subtitle}
        </p>
      </div>
      <ul className="mt-4 text-left flex-grow">
        {props.bulletPoints.map((point, index) => (
          <li
            key={index}
            className="flex items-start text-sm font-[Poppins] text-[14px] font-normal leading-[22px] text-left text-[#353535] mb-2" // Add mb-2 for margin-bottom
          >
            <span className="mt-2 flex items-center justify-center">
              <div className="mr-2 relative w-[16px] h-[16px] bg-[#8C3EA0] rounded-full">
                <img
                  src={CustomBulletPoints}
                  alt="Bullet Point"
                  className="absolute w-full h-full object-contain"
                  style={{ transform: 'translate(20%, -10%)' }}
                />
              </div>
            </span>
            {point.text}
          </li>
        ))}
      </ul>
      <button
        className="whitespace-nowrap px-16p py-20p sm:px-64p text-center text-btn uppercase font-semibold rounded-16 text-white border-transparent cursor-pointer bg-gradient-to-r from-dg-secondary to-dg-primary-900 transition-all hover:bg-gradient-to-r hover:to-dg-secondary hover:from-dg-primary-900"
        onClick={() => link('/contact')}
      >
        {props.buttonTitle}
      </button>
    </div>
  );
}

PricingCard.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  bulletPoints: propTypes.arrayOf(propTypes.string).isRequired,
  buttonTitle: propTypes.string.isRequired,
};
