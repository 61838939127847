import React from 'react';
import Animation_Diligent from '../../assets/animation_diligent.webm';
import Wrapper from '../../layout/Wrapper';
import useFetchCollections from './../../hooks/useFetchCollections';
import { strapiApiBuilder } from './../../utils/strapiApiBuilder';
import ArticleCard from './ArticleCard';
import ArticlesGrid from './ArticlesGrid';

const strapiPopulate = ['AuthorImage', 'ArticleImage', 'article_category'];

const BlogArticlesDataWrapper = () => {
  const strapi = strapiApiBuilder('articles', strapiPopulate, '&sort=Date:desc');

  const [{ data, isLoading, isError }, doFetch] = useFetchCollections(strapi);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else {
    return (
      <>
        {data && (
          <Wrapper>
            <ArticlesGrid>
              {data.map((element, index) => (
                <div key={index}>
                  <ArticleCard data={element.attributes} />
                </div>
              ))}
            </ArticlesGrid>
          </Wrapper>
        )}
      </>
    );
  }
};
export default BlogArticlesDataWrapper;
