import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/root/ScrollToTop';
import CookieBanner from './components/shared/CookieBanner';
import Footer from './components/shared/Footer';
import Loader from './components/shared/Loader';
import Navigation from './components/shared/Navigation';
import UIContextProvider from './context/UIContextProvider';
import routes from './routes';

const Home = lazy(() => import('./pages/Home'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const Careers = lazy(() => import('./pages/Careers'));
const Culture = lazy(() => import('./pages/Culture'));
const About = lazy(() => import('./pages/About'));
const Blog = lazy(() => import('./pages/Blog'));
const ProcessPage = lazy(() => import('./pages/ProcessPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const WorkWithUs = lazy(() => import('./pages/WorkWithUs'));
const StartupCorner = lazy(() => import('./pages/StartupCorner'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ArticlePage = lazy(() => import('./pages/ArticlePage'));
const CaseStudyPage = lazy(() => import('./pages/CaseStudyPage'));
const Pricing = lazy(() => import('./pages/Pricing'));

const links = routes.filter(item => item.nav === true);

function App() {
  const link = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [diligentCookie, setDiligentCookie] = useState(cookies.diligent_ga);
  const forwardedRef = useRef(null);

  useEffect(() => {
    if (diligentCookie === 'true') {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
    }
  }, [diligentCookie]);

  useEffect(() => {
    const initApollo = () => {
      const n = Math.random().toString(36).substring(7);
      const o = document.createElement('script');

      o.src = `https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=${n}`;
      o.async = true;
      o.defer = true;

      o.onload = () => {
        window.trackingFunctions.onLoad({ appId: '6655c94a3ca99201ebc8d39b' });
      };

      document.head.appendChild(o);
    };

    initApollo();
  }, []);

  function scrollToView(event) {
    event.preventDefault();
    if (forwardedRef) {
      window.scrollTo({ behavior: 'smooth', top: forwardedRef.current.offsetTop });
    } else {
      link('/contact');
    }
  }

  function activeLinks(activePage, bool = true) {
    const text = 'text-black';
    if (bool === true) {
      return activePage ? 'activeLink dg-primary-900 ' : text;
    } else {
      return text;
    }
  }

  const handleAccept = () => {
    setCookie('diligent_ga', 'true', { path: '/' });
    setDiligentCookie(prevValue => !prevValue);
  };

  const handleDecline = () => {
    setCookie('diligent_ga', 'false', { path: '/' });
    setDiligentCookie(prevValue => !prevValue);
  };

  return (
    <div>
      <ScrollToTop />
      <UIContextProvider>
        <Navigation
          links={links}
          scrollToView={scrollToView}
          activeLinks={activeLinks}
          forwardedRef={forwardedRef}
        ></Navigation>
        {diligentCookie === undefined && (
          <CookieBanner handleAccept={handleAccept} handleDecline={handleDecline} />
        )}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path="/" element={<Home forwardedRef={forwardedRef} />} />
            <Route exact path="/professional-service" element={<WorkWithUs />} />
            <Route exact path="/startup-corner" element={<StartupCorner />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/case-studies/:slug" element={<CaseStudyPage />} />
            <Route exact path="/process" element={<ProcessPage />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route exact path="/culture" element={<Culture />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/articles/:slug" element={<ArticlePage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>

        <Footer
          links={links}
          activeLinks={activeLinks}
          scrollToView={scrollToView}
          forwardedRef={forwardedRef}
        />
      </UIContextProvider>
    </div>
  );
}

export default App;
