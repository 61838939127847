"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpoints = void 0;
var react_1 = require("react");
var useBreakpoints = function (props) {
    var _a = (0, react_1.useState)(props), propsToUse = _a[0], setPropsToShow = _a[1];
    var animationRef = (0, react_1.useRef)(null);
    var requestAnimation = (0, react_1.useCallback)(function () {
        var breakpoints = props.breakpoints;
        if (animationRef.current)
            cancelAnimationFrame(animationRef.current);
        if (breakpoints) {
            animationRef.current = requestAnimationFrame(function () {
                var matchedBreakpoints = Object.keys(breakpoints).map(function (breakpoint) {
                    var matches = window.matchMedia(breakpoint).matches;
                    if (matches)
                        return breakpoint;
                    return undefined;
                }).filter(Boolean);
                if (matchedBreakpoints.length === 0) {
                    setPropsToShow(props);
                }
                else {
                    var lastMatch = matchedBreakpoints[matchedBreakpoints.length - 1];
                    var breakpointProps = breakpoints[lastMatch];
                    setPropsToShow(__assign(__assign({}, props), breakpointProps));
                }
            });
        }
        setPropsToShow(props);
    }, [props]);
    var requestThrottledAnimation = (0, react_1.useCallback)(function () {
        setTimeout(function () {
            requestAnimation();
        }, 500);
    }, [requestAnimation]);
    (0, react_1.useEffect)(function () {
        window.addEventListener('resize', requestAnimation);
        window.addEventListener('orientationchange', requestThrottledAnimation);
        requestAnimation();
        return function () {
            window.removeEventListener('resize', requestAnimation);
            window.removeEventListener('orientationchange', requestThrottledAnimation);
        };
    }, [
        requestAnimation,
        requestThrottledAnimation,
    ]);
    (0, react_1.useEffect)(function () {
        (function () {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        });
    }, []);
    return propsToUse;
};
exports.useBreakpoints = useBreakpoints;
