"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useIntersection = function (ref, options) {
    var _a = options || {}, root = _a.root, rootMargin = _a.rootMargin, threshold = _a.threshold;
    var _b = (0, react_1.useState)({}), intersection = _b[0], setIntersection = _b[1];
    (0, react_1.useEffect)(function () {
        var observer;
        var currentRef = ref.current;
        if (currentRef) {
            observer = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    setIntersection(entry);
                });
            }, {
                root: (root === null || root === void 0 ? void 0 : root.current) || null,
                rootMargin: rootMargin || '0px',
                threshold: threshold || 0.05,
            });
            observer.observe(currentRef);
        }
        return function () {
            if (observer && currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [
        ref,
        root,
        rootMargin,
        threshold,
    ]);
    return intersection;
};
exports.default = useIntersection;
