import React, { useEffect, useState } from 'react';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import useFetchCollections from '../hooks/useFetchCollections';
import Animation_Diligent from '../assets/animation_diligent.webm';
import Testimonials from '../components/Testimonials';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = ['Image'];

const TestimonialsDataLayer = () => {
  const strapi = strapiApiBuilder('testemonials', strapiPopulate, '');
  const [wideArray,setWideArray] = useState([]);

  const [{ data, isLoading, isError }, doFetch] = useFetchCollections(strapi);

  useEffect(() => {
    if (!isLoading) {
        let finalArray = [];
        data && data.map((item,index,array) => {
            if ( index % 2 === 0) {
                const temp = [array[index], array[index+1]];
                finalArray.push(temp);
                
            } else if (array.length === index) {
                const temp = array[index]
                finalArray.push(temp);
            }
        })
        setWideArray(finalArray);
    }
    
  },[data]);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }


  return (
     <Testimonials data={data} wideArray={wideArray} />
  );
};

export default TestimonialsDataLayer;
