import React from 'react';
import { useNavigate } from 'react-router-dom';
import TriangleButton from '../shared/TriangleButton';

const ExpandedCard = ({
  card,
  setExpanded,
  setExpandedCard,
  forwarderdRef,
  setJobForm,
  jobForm,
}) => {
  const linkTo = useNavigate();

  const changeContext = value => {
    setJobForm({
      ...jobForm,
      position: value,
    });
  };

  const handleNavigate = tab => {
    changeContext(card.tag);
    linkTo('/contact', { tab: { tab } });
  };
  return (
    <div
      ref={forwarderdRef}
      className={
        'dark:text-white card-no-hover w-full h-fit shadow-md rounded-md px-12 lg:px-32 py-12 lg:py-16'
      }
    >
      <div className="flex flex-col items-start justify-center text-left">
        <div className="flex flex-row items-center justify-start">
          <div className="mr-4 lg:hidden">
            <TriangleButton
              onClick={() => {
                setExpanded(false);
                setExpandedCard({});
              }}
              direction={'left'}
            />
          </div>
          <div className="hidden lg:block absolute top-20 md:top-[15rem] left-12">
            <TriangleButton
              onClick={() => {
                setExpanded(false);
                setExpandedCard({});
              }}
              direction={'left'}
            />
          </div>

          <img
            src={card.icon.data.attributes.url}
            className="ml-auto mr-auto block w-[70px] text-dark-gray bg-none rounded-full"
          />
          <h3 className="ml-6 w-full font-semibold text-2xl">{card.title}</h3>
        </div>
        <p className="mt-6 text-sm">{card.experience}</p>
        <h4 className="mt-6 font-semibold text-lg">{card.keyTitle}:</h4>
        <ul>
          {card &&
            card.keys &&
            card.keys.map((item, index) => (
              <li className="list-disc ml-6" key={index}>
                {item.ParagraphElement}
              </li>
            ))}
        </ul>
        <br />
        <h4 className="mt-6 font-semibold text-lg">{card.reqTitle}:</h4>
        <ul>
          {card &&
            card.reqs &&
            card.reqs.map((item, index) => (
              <li className="list-disc ml-6" key={index}>
                {item.ParagraphElement}
              </li>
            ))}
        </ul>
        <br />
        <h4 className="mt-6 font-semibold text-lg">{card.offerTitle}:</h4>
        <ul>
          {card.offers &&
            card.offers.map((item, index) => (
              <li className="list-disc ml-6" key={index}>
                {item.ParagraphElement}
              </li>
            ))}
        </ul>
        <br />
        <div className="flex flex-row w-full items-center justify-end">
          <a
            href="#"
            className="btn btn_primary mt-8 transition-all hover:transition-all"
            onClick={() => handleNavigate(false)}
          >
            Apply
          </a>
        </div>
      </div>
    </div>
  );
};

ExpandedCard.propTypes = {};

export default ExpandedCard;
