import React from 'react';

import PropTypes from 'prop-types';

const Wrapper = ({ bg, padding, children, hideOverflow, gradient }) => {
  return (
    <div
      className={
        'w-full h-fit' +
        (bg ? ' bg-baby-blue dark:bg-dg-primary-1600' : '') +
        padding +
        (hideOverflow ? ' overflow-hidden' : '') +
        (gradient ? ' bg-gradient-to-r from-[#90278F] to-[#8468BF]' : '')
      }
    >
      <div className="max-w-custom relative mx-auto px-16p">{children}</div>
    </div>
  );
};
Wrapper.propTypes = {
  bg: PropTypes.bool,
  gradient: PropTypes.bool,
  padding: PropTypes.string,
  children: PropTypes.node,
};
export default Wrapper;
