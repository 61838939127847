import React, { useState, useEffect } from 'react';
import useFetchCollections from '../hooks/useFetchCollections';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import Animation_Diligent from '../assets/animation_diligent.webm';
import PortfolioCardNew from '../components/shared/PortfolioCardNew';
import PortfolioTestimonial from '../components/PortfolioTestimonial';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [
  'Slug',
  'Heading',
  'Heading.paragraphs',
  'BackgroundImage',
  'Stat',
  'Industry',
];

const PortfolioDataLayer = ({ filter, testemonials }) => {
  const strapi = strapiApiBuilder('case-studies', strapiPopulate, filter ? filter : '');
  const [{ data, isLoading }] = useFetchCollections(strapi);

  const [selectedIndustries, setSelectedIndustries] = useState(['All']);

  useEffect(() => {
    if (data) {
      if (selectedIndustries.includes('All')) {
        setSelectedIndustries(['All']);
      }
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  if (!isLoading && data) {
    const uniqueIndustries = Array.from(
      new Set(
        data
          .map(item =>
            item.attributes.Industry && item.attributes.Industry.info
              ? item.attributes.Industry.info
              : null,
          )
          .filter(Boolean),
      ),
    );

    const filteredData = selectedIndustries.includes('All')
      ? data
      : data.filter(
          item =>
            item.attributes.Industry &&
            selectedIndustries.includes(item.attributes.Industry.info),
        );

    const toggleIndustry = industry => {
      if (industry === 'All') {
        if (selectedIndustries.includes('All')) {
          setSelectedIndustries([]);
        } else {
          setSelectedIndustries(['All']);
        }
      } else {
        setSelectedIndustries(prev => {
          if (prev.includes(industry)) {
            return prev.filter(ind => ind !== industry);
          } else {
            return [...prev.filter(ind => ind !== 'All'), industry];
          }
        });
      }
    };

    return (
      <div className="flex flex-col p-4">
        <div
          className={`${
            filter && 'hidden'
          } flex flex-col md:flex-row justify-between items-center mb-4`}
        >
          <h2 className="font-[Poppins] text-[32px] font-semibold leading-[40px] text-left text-[#8E3499]">
            Our finest work
          </h2>
          <div className="flex space-x-4 mt-2 md:mt-0">
            <button
              className={`text-white rounded px-2 py-1 md:px-4 md:py-2 ${
                selectedIndustries.includes('All')
                  ? 'bg-gradient-to-r from-[#90278F] to-[#8468BF]'
                  : 'bg-[#D1D3F1]'
              }`}
              onClick={() => toggleIndustry('All')}
            >
              All
            </button>
            {uniqueIndustries.map((industry, index) => (
              <button
                key={index}
                className={`text-white rounded px-2 py-1 md:px-4 md:py-2 ${
                  selectedIndustries.includes(industry)
                    ? 'bg-gradient-to-r from-[#90278F] to-[#8468BF]'
                    : 'bg-[#D1D3F1]'
                }`}
                onClick={() => toggleIndustry(industry)}
              >
                {industry}
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap justify-center">
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <React.Fragment key={index}>
                <div className="w-full sm:w-3/5 md:w-1/2 p-2">
                  <PortfolioCardNew data={item.attributes} />
                </div>
                {(index + 1) % 4 === 0 && !filter && testemonials && (
                  <PortfolioTestimonial testimonial={testemonials[(index + 1) / 4 - 1]} />
                )}
              </React.Fragment>
            ))
          ) : (
            <div className="w-full text-center">
              No portfolio items found for this industry.
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PortfolioDataLayer;
