import React from 'react';
import PropTypes from 'prop-types';
import { Slide, SliderButton, SliderProvider, SliderTrack } from '@faceless-ui/slider';
import { ReactComponent as CircleArrow } from '../../assets/icons/CircleArrow.svg';

import ProcessSvgPart1 from './../../assets/ProcessPart1.svg';
import ProcessSvgPart2 from './../../assets/ProcessPart2.svg';
import ProcessSvgPart3 from './../../assets/ProcessPart3.svg';

const api_url = process.env.REACT_APP_API_URL;

const ProcessFacelessSlider = ({images}) => {

  const slides = [images[0].attributes,images[1].attributes, images[2].attributes];
  return (
    <SliderProvider slidesToShow={1} scrollSnap dragScroll>
      <div className={''}>
        {/* <SliderButton direction="prev">
        Previous
      </SliderButton>
      <SliderButton direction="next">
        Next
      </SliderButton> */}
      </div>
      <div className="rounded-xl overflow-hidden mt-4">
        <SliderTrack className={' track h-full mt-10 rounded-xl'}>
          {slides.map((slide, index) => (
            <Slide key={index} index={index} className={'h-full'}>
              <img
                src={slide.url}
                alt={slide.alternativeText}
                className={'h-full mx-auto'}
              ></img>
            </Slide>
          ))}
        </SliderTrack>
      </div>
      <div className="flex justify-between w-2/5 mt-8 mx-auto">
        <SliderButton direction="prev">
          <CircleArrow />
        </SliderButton>
        <SliderButton direction="next">
          <CircleArrow className="rotate-180" />
        </SliderButton>
      </div>

      {/* <DotsNav
            className={"dots"}
            dotClassName={"dot"}
            activeDotClassName={"dotIsActive"}
          /> */}
    </SliderProvider>
  );
};

ProcessFacelessSlider.propTypes = {};

export default ProcessFacelessSlider;
