import React, { Fragment, useEffect } from 'react';
import '../App.css';
import Animation_Diligent from '../assets/animation_diligent.webm';
import BlogArticlesDataWrapper from '../components/shared/BlogArticlesDataWrapper';
import useDataApi from '../hooks/useDataApi';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import PageTitle from './../components/shared/PageTitle';
import ReactHelmet from './../components/shared/ReactHelmet';
import useAnalytics from './../hooks/useAnalytics';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [
  'Heading',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

export default function Blog() {
  const strapi = strapiApiBuilder('blogpage', strapiPopulate, '');

  const [{ data, isLoading }] = useDataApi(strapi);

  useAnalytics('Blog');

  useEffect(() => {
    document.title = 'Blog';
  }, []);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else {
    return (
      <PageLayout>
        {data && data.SEO && <ReactHelmet seo={data.SEO} />}
        <div className="mt-[80px] md:mt-[100px]">
          <Wrapper padding={' py-[48px]'}>
            {data ? (
              <Fragment>
                <PageTitle
                  heading={data.Heading.title}
                  subheading={data.Heading.subtitle}
                  left
                />
              </Fragment>
            ) : null}
          </Wrapper>
          <BlogArticlesDataWrapper />
        </div>
      </PageLayout>
    );
  }
}
