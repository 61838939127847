import React, { createContext, useState } from 'react';
import { useContext } from 'react';

export const UIContext = createContext({
  jobForm: {
    tag: '',
    subject: '',
    firstName: '',
    lastName: '',
    email: '',
    description: '',
    position: '',
  },
  setJobForm: () => {},
  tab: true,
  setTab: () => {},
});

const UIContextProvider = ({ children }) => {
  const uiContext = useContext(UIContext);

  const [jobForm, setJobForm] = useState(uiContext.jobForm);
  const [tab, setTab] = useState(uiContext);

  const values = {
    jobForm,
    setJobForm,
    tab,
    setTab,
  };

  // const changeUI = (object,prop,value) => {
  //   if (value) {
  //     setUi(prevState => ({
  //       ...prevState,
  //       [object]: {
  //         [prop]: value,
  //       },
  //     }));
  // }};

  return (
    <>
      <UIContext.Provider value={values}>{children}</UIContext.Provider>
    </>
  );
};

export default UIContextProvider;
