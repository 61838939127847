import React, { Fragment } from 'react';
import ReadMoreIcon from './../../assets/icons/read-more.svg';

const PortfolioCardNew = ({ data }) => {
  return (
    <Fragment>
      {data && (
        <a
          className="card box my-2 flex flex-col w-full h-[800px] overflow-hidden relative p-0"
          href={`/case-studies/${data.Slug}`}
        >
          {data.BackgroundImage && (
            <img
              src={data.BackgroundImage.data.attributes?.url}
              alt={data.BackgroundImage.data.attributes?.alternativeText}
              className="w-full h-[300px] object-cover"
            />
          )}

          <div className="flex flex-col p-8">
            <h3 className="font-[Poppins] text-[30px] font-semibold leading-[40px] tracking-[1px] text-left text-[#353535]">
              {data.Heading.title}
            </h3>
            <p className="mt-2 font-[Poppins] text-[18px] font-semibold leading-[25.2px] tracking-[1px] text-left text-[#BC78DC]">
              {data.Heading.paragraphs[0].ParagraphElement}
            </p>
            <div className="flex flex-wrap mt-4">
              {data.Stat.map((item, index) => (
                <div key={index} className="w-1/2 p-2">
                  <p className="font-[Poppins] text-[32px] font-bold leading-[48px] tracking-[1px] text-left text-[#90278F]">
                    {item.value}
                  </p>
                  <p className="font-[Poppins] text-[16px] font-normal leading-[17.6px] tracking-[1px] text-left text-[#727272]">
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <button
            className="absolute bottom-4 right-4 flex items-center text-[16px] font-semibold leading-[22px] text-right uppercase"
            style={{
              background: 'linear-gradient(90deg, #9F36F0, #922994)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Read More
            <img src={ReadMoreIcon} alt="Read More" />
          </button>
        </a>
      )}
    </Fragment>
  );
};

export default PortfolioCardNew;
