import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReactComponent as TimelineLine } from './../assets/TimelineLine.svg';

const TimelineCardsWrapper2 = ({ children }) => {
  const ref = useRef(null);

  const [view, setView] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [top, setTop] = useState(0);
  const [docH, setDocH] = useState(0);
  const handleNavigation = useCallback(
    e => {
      const doc = document.documentElement.offsetHeight;
      const window = e.currentTarget;

      if (y > window.scrollY) {
        //up
        if (view && top >= 0) setTop(prev => prev - (y - window.scrollY));
      } else if (y < window.scrollY) {
        //down
        if (view) setTop(prev => prev - (y - window.scrollY));
      }
      setY(window.scrollY);
    },
    [y],
  );

  useLayoutEffect(() => {
    const temp1 = ref.current.getBoundingClientRect();
    const top = temp1.top;
    const ask = top < window.innerHeight / 2;
    setView(ask);
    const h = window.document.body.offsetHeight;
    setDocH(h);
  });

  const [test, setTest] = useState(0);
  const [startMargin, setStartMargin] = useState(0);
  const [endMargin, setEndMargin] = useState(0);

  useLayoutEffect(() => {
    const line = ref.current.getBoundingClientRect();
    const midVH = window.innerHeight / 2;

    const topOfElement = y - line.top;
    const bottomOfElement = y + line.bottom;

    //if start <0 stop increment
    setStartMargin(topOfElement - y + midVH);

    // if end >0 stop increment
    setEndMargin(bottomOfElement - y - midVH);

    //setView(ask);
    const doch = window.document.body.offsetHeight;
    //setDocH(h);
    const widnow2 = document.documentElement.offsetHeight;
  });

  const handleScroll2 = useCallback(() => {
    if (startMargin > 0 && endMargin > 0) setTest(startMargin);
    setY(window.scrollY);
  }, [y]);

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScroll2);

    return () => {
      window.removeEventListener('scroll', handleScroll2);
    };
  }, [handleScroll2]);

  return (
    <div>
      <motion.div className="w-full relative flex flex-col gap-8 lg:gap-0">
        <motion.div
          className="hidden md:block absolute -left-10 lg:left-[41.5%] top-[10%] w-max z-10 h-full"
          ref={ref}
        >
          <motion.div className="top-0 w-max">
            <TimelineLine className="overflow-visible" />
          </motion.div>
        </motion.div>

        {children}
      </motion.div>
    </div>
  );
};

TimelineCardsWrapper2.propTypes = {};

export default TimelineCardsWrapper2;
