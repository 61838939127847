"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollToIndex = void 0;
var react_1 = require("react");
var useScrollToIndex = function (args) {
    var sliderState = args.sliderState, onSlide = args.onSlide, scrollOffset = args.scrollOffset, sliderTrackRef = args.sliderTrackRef;
    var animationRef = (0, react_1.useRef)();
    var prevScrollIndex = (0, react_1.useRef)();
    var scrollToIndex = (0, react_1.useCallback)(function (incomingSlideIndex) {
        var hasIndex = sliderState.slides[incomingSlideIndex];
        if (animationRef.current)
            cancelAnimationFrame(animationRef.current);
        if (hasIndex && sliderTrackRef.current) {
            var targetSlide = sliderState.slides[incomingSlideIndex];
            var targetSlideRef = targetSlide.ref.current;
            if (targetSlideRef) {
                var offsetLeft_1 = targetSlideRef.offsetLeft;
                animationRef.current = requestAnimationFrame(function () {
                    if (sliderTrackRef.current) {
                        sliderTrackRef.current.scrollTo({
                            top: 0,
                            left: (offsetLeft_1 - (scrollOffset || 0)),
                            behavior: 'smooth',
                        });
                    }
                });
            }
            if (typeof onSlide === 'function')
                onSlide(incomingSlideIndex);
        }
    }, [
        sliderState.slides,
        onSlide,
        scrollOffset,
        sliderTrackRef
    ]);
    // auto-scroll to the target index only when "scrollIndex" changes
    (0, react_1.useEffect)(function () {
        if (sliderState.scrollIndex !== undefined && prevScrollIndex.current !== sliderState.scrollIndex) {
            scrollToIndex(sliderState.scrollIndex);
            prevScrollIndex.current = sliderState.scrollIndex;
        }
    }, [
        sliderState.scrollIndex,
        scrollToIndex,
    ]);
    (0, react_1.useEffect)(function () {
        (function () {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        });
    }, []);
    return null;
};
exports.useScrollToIndex = useScrollToIndex;
