import React from 'react';
import ProcessFacelessSlider from './shared/ProcessFacelessSlider';
import ProcessSliderWrapper from './shared/ProcessSliderWrapper';

const ProcessSlider = ({ images }) => {
  return (
    <>
      <ProcessSliderWrapper>
        <ProcessFacelessSlider images={images} />
      </ProcessSliderWrapper>
    </>
  );
};

ProcessSlider.propTypes = {};

export default ProcessSlider;
