import React from 'react';
import { ReactComponent as TestemonialsSVG } from '../../../assets/graphics/TestemonialsSVG.svg';

export default function TestimonialCard({
  paragraph,
  clientName,
  clientRole,
  clientImg,
  imageAlt,
}) {
  const cardClassList =
    'px-12 py-12 bg-white dark:bg-dg-primary-1700 text-dark-gray dark:text-white shadow-md dark:border-dg-primary-1500 dark:border-solid dark:border text-center rounded-xl';

  return (
    <div className="relative h-fit md:mr-90p">
      <div className="absolute z-20 -bottom-[120px] -right-[90px] hidden md:block">
        <TestemonialsSVG />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
          <img
            src={clientImg}
            alt={imageAlt}
            className="mx-auto"
            style={{
              margin: '2px 2px 40px 20px',
              height: 64,
              width: 64,
              borderRadius: '50%',
            }}
          />
        </div>
      </div>
      <div className={cardClassList + ' flex flex-col justify-between max-w-[460px]'}>
        <p className="text-p-italic italic text-gray-500">"{paragraph}"</p>
        <h4 className="text-dg-primary-900">{clientName}</h4>
        <p>{clientRole}</p>
      </div>
    </div>
  );
}
