"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDraggable = void 0;
var react_1 = require("react");
var useDraggable = function (args) {
    var _a = args || {}, _b = _a.buttons, buttons = _b === void 0 ? [1, 4, 5] : _b, // See https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
    scrollYAxis = _a.scrollYAxis, enable = _a.enable, ref = _a.ref, onDrag = _a.onDrag, onDragEnd = _a.onDragEnd;
    // Position of the mouse on the page on mousedown
    var _c = (0, react_1.useState)(0), startX = _c[0], setStartX = _c[1];
    var _d = (0, react_1.useState)(0), startY = _d[0], setStartY = _d[1];
    // Amount the draggable element is already scrolled
    var _e = (0, react_1.useState)(0), startScrollLeft = _e[0], setStartScrollLeft = _e[1];
    var _f = (0, react_1.useState)(0), startScrollTop = _f[0], setStartScrollTop = _f[1];
    (0, react_1.useEffect)(function () {
        var handleDown = function (e) {
            if ((ref === null || ref === void 0 ? void 0 : ref.current) && enable) {
                // Only allow dragging inside of target element
                if (!ref.current.contains(e.target)) {
                    return;
                }
                // Set initial positions of mouse element scroll
                setStartX(e.pageX - ref.current.offsetLeft);
                setStartY(e.pageY - ref.current.offsetTop);
                setStartScrollLeft(ref.current.scrollLeft);
                setStartScrollTop(ref.current.scrollTop);
                if (typeof onDrag === 'function') {
                    onDrag();
                }
            }
        };
        var handleMove = function (e) {
            if ((ref === null || ref === void 0 ? void 0 : ref.current) && enable) {
                // Don't fire if other buttons are pressed
                if (!buttons.includes(e.buttons) || !ref.current.contains(e.target)) {
                    return;
                }
                if (ref.current.children) {
                    // disable nested anchor links from navigating while dragging
                    var childrenAsArray = Array.from(ref.current.children);
                    childrenAsArray.forEach(function (child) {
                        var childAsElement = child;
                        childAsElement.style.pointerEvents = 'none';
                    });
                }
                e.preventDefault();
                // Position of mouse on the page
                var mouseX = e.pageX - ref.current.offsetLeft;
                var mouseY = e.pageY - ref.current.offsetTop;
                // Distance of the mouse from the origin of the last mousedown event
                var xDisplacement = mouseX - startX;
                var yDisplacement = mouseY - startY;
                // Finally, set the element's scroll
                ref.current.scrollLeft = startScrollLeft - xDisplacement;
                var newScrollTop = startScrollTop - yDisplacement;
                if (scrollYAxis !== false) {
                    ref.current.scrollTop = newScrollTop;
                }
            }
        };
        var handleUp = function () {
            var _a;
            // reenable nested anchor links after dragging
            if (((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.children) && enable) {
                var childrenAsArray = Array.from(ref.current.children);
                childrenAsArray.forEach(function (child) {
                    var childAsElement = child;
                    childAsElement.style.removeProperty('pointer-events');
                });
                if (typeof onDragEnd === 'function') {
                    onDragEnd();
                }
            }
        };
        // Add and clean up listeners
        document.addEventListener('mousedown', handleDown);
        document.addEventListener('mousemove', handleMove);
        document.addEventListener('mouseup', handleUp);
        return function () {
            document.removeEventListener('mousedown', handleDown);
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('mouseup', handleUp);
        };
    }, [
        buttons,
        startScrollLeft,
        startScrollTop,
        startX,
        startY,
        scrollYAxis,
        enable,
        ref,
        onDragEnd,
        onDrag
    ]);
    return null;
};
exports.useDraggable = useDraggable;
exports.default = exports.useDraggable;
