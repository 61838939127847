import React from 'react';
import { ReactComponent as Net } from './../assets/images/TechStack/netSvg.svg';
import { ReactComponent as Node } from './../assets/images/TechStack/nodejs.svg';
import { ReactComponent as Angular } from './../assets/images/TechStack/angular.svg';
import { ReactComponent as ReactSvg } from './../assets/images/TechStack/react.svg';

function StackWrapper() {
  return (
    <div className="w-full flex flex-col md:flex-row justify-between px-90p flex-wrap gap-x-4 gap-y-8">
      <Net className="mx-auto" />
      <Node className="mx-auto" />
      <Angular className="mx-auto" />
      <ReactSvg className="mx-auto" />
    </div>
  );
}

export default StackWrapper;
