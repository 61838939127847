import { motion } from 'framer-motion';
import '../../../styles/graphics.css';

const transition = { type: 'tween', duration: 1 };

export default function OrbitOnClick({ tab }) {
  const currentPrecentCircle = tab == 0 ? '78%' : '99.99999%';
  const currentPrecentTriangle = tab == 0 ? '50.99%' : '78%';
  const currentAngleTriangle = tab == 0 ? 30 : 90;

  return (
    <div className="relative">
      <svg
        width="203"
        height="113"
        viewBox="0 0 203 113"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          d="M112.942 0.571399C93.099 0.259545 73.0378 3.23211 55.2958 9.11316C37.5538 14.9942 22.9279 23.5196 13.2676 33.6112C3.60739 43.7028 -0.653255 54.9073 1.02447 65.8079C2.7022 76.7084 10.2429 86.8154 22.6931 94.8506C35.1433 102.886 51.9437 108.488 70.9697 110.95C89.9957 113.411 110.393 112.621 129.581 108.679C148.77 104.737 165.888 97.8199 178.772 88.8032C191.655 79.7864 199.725 69.0746 201.96 58.0225C204.925 43.2081 197.218 28.8402 180.53 18.0702C163.842 7.30009 139.536 1.00709 112.942 0.571399ZM90.4392 111.832C70.7732 111.524 52.2058 107.974 37.0849 101.633C21.964 95.2915 10.9688 86.4426 5.48965 76.2053C0.0105336 65.9679 0.293642 54.8018 6.30309 44.1192C12.3125 33.4365 23.7785 23.717 39.2509 16.1897C54.7234 8.66238 73.5074 3.66543 93.2277 1.83069C112.948 -0.00405469 132.719 1.40583 150.04 5.88206C167.361 10.3583 181.455 17.6998 190.539 26.9783C199.623 36.2567 203.289 47.0553 201.073 58.0086C198.063 72.6868 184.727 86.5931 163.988 96.6788C143.25 106.764 116.802 112.206 90.4434 111.811L90.4392 111.832Z"
          fill="#BBBBBB"
        />
      </svg>

      <motion.div
        className="circle-click"
        //initial={{ offsetDistance: "30%" }}
        initial={{ offsetDistance: currentPrecentCircle }}
        animate={{ offsetDistance: currentPrecentCircle }}
        transition={transition}
        //style={{ offsetDistance: currentPrecent }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5401 15.3919C14.6487 14.0039 16.8542 9.54794 15.4661 5.43931C14.0781 1.33068 9.62218 -0.874801 5.51355 0.513233C1.40492 1.90127 -0.800558 6.35719 0.587475 10.4658C1.97551 14.5745 6.43143 16.7799 10.5401 15.3919Z"
            fill="#A1A3DD"
          />
        </svg>
      </motion.div>
      <motion.div
        className="triangle-click"
        initial={{
          offsetDistance: currentPrecentTriangle,
          rotate: currentAngleTriangle,
        }}
        animate={{
          offsetDistance: currentPrecentTriangle,
          rotate: currentAngleTriangle,
        }}
        transition={transition}
        // style={{
        //   rotate: currentAngleTriangle,
        // }}
        // transition={transitionTriangle}
      >
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.444359 5.67918C-0.148274 5.13244 0.116617 4.14514 0.903334 3.96848L14.3915 0.939655C15.0739 0.786437 15.6987 1.36295 15.6008 2.05537L13.6654 15.7423C13.5525 16.5406 12.5898 16.8839 11.9972 16.3372L0.444359 5.67918Z"
            fill="#FFA463"
          />
        </svg>
      </motion.div>
    </div>
  );
}

OrbitOnClick.propTypes = {};
