"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var reducer = function (state, action) {
    var newState = __assign({}, state);
    var currentSlideIndex = state.currentSlideIndex, selectedSlideIndex = state.selectedSlideIndex, slides = state.slides;
    var type = action.type, payload = action.payload;
    switch (type) {
        case 'UPDATE_SLIDE': {
            var slide = payload.slide;
            if (slide) {
                var slideIndex = slide.index;
                newState.slides[slideIndex] = slide;
            }
            break;
        }
        case 'GO_TO_SLIDE_INDEX': {
            var index = payload.index;
            if (typeof index === 'number') {
                newState.scrollIndex = index;
            }
            break;
        }
        case 'GO_TO_NEXT_SLIDE': {
            var loop = payload.loop, isFullyScrolled = payload.isFullyScrolled;
            var currentIndex = selectedSlideIndex || currentSlideIndex;
            var nextIndex = currentIndex + 1;
            var hasNext = nextIndex < slides.length;
            var indexToUse = nextIndex;
            if (!hasNext || isFullyScrolled) {
                if (loop)
                    indexToUse = 0; // first slide
                else
                    indexToUse = slides.length - 1; // last slide
            }
            newState.scrollIndex = indexToUse;
            break;
        }
        case 'GO_TO_PREV_SLIDE': {
            var loop = payload.loop;
            var currentIndex = selectedSlideIndex || currentSlideIndex;
            var prevIndex = currentIndex - 1;
            var hasPrev = prevIndex >= 0;
            var indexToUse = prevIndex;
            if (!hasPrev) {
                if (loop)
                    indexToUse = slides.length - 1;
                else
                    indexToUse = 0;
            }
            newState.scrollIndex = indexToUse;
            break;
        }
        default: {
            break;
        }
    }
    var allIntersections = state === null || state === void 0 ? void 0 : state.slides.map(function (_a) {
        var isIntersecting = _a.isIntersecting;
        return isIntersecting;
    });
    var newSlideIndex = allIntersections.indexOf(true); // first intersecting slide
    if (newSlideIndex === -1)
        newSlideIndex = 0; // if none intersection, use first slide
    newState.currentSlideIndex = newSlideIndex;
    return newState;
};
exports.default = reducer;
