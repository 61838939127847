import { motion } from 'framer-motion';
import PortfolioDataLayer from '../api/PortfolioDataLayer';
import Animation_Diligent from '../assets/animation_diligent.webm';
import GradientWrapper from '../components/shared/GradientWrapper';
import PageTitle from '../components/shared/PageTitle';
import ReactHelmet from '../components/shared/ReactHelmet';
import useAnalytics from '../hooks/useAnalytics';
import useDataApi from '../hooks/useDataApi';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';

const strapiPopulate = [
  'heading.heading',
  'heading.subheading',
  'heading.paragraph',
  'HeroNumbers.number',
  'Testemonial',
  'Testemonial.client_image',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

export default function Portfolio() {
  const strapi = strapiApiBuilder('portfoliopage', strapiPopulate, '');

  const [{ data, isLoading }] = useDataApi(strapi);

  useAnalytics('Case Studies');

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  if (!isLoading && data) {
    return (
      <PageLayout>
        {data && data.SEO && <ReactHelmet seo={data.SEO} />}
        <div className="flex flex-col gap-16 pt-32">
          <Wrapper>
            <h1 className="hidden">Our Work - Case Studies</h1>
            <div className="flex flex-col gap-8p bg-gradient-to-r from-purple-500 to-purple-700 bg-clip-text text-transparent">
              <PageTitle
                heading={data.heading.heading}
                subheading={data.heading.subheading}
                left
                color="bg-gradient-to-r from-purple-500 to-purple-700 bg-clip-text"
              />
              <p className="paragraph">{data.heading.paragraph}</p>
            </div>
          </Wrapper>
          <GradientWrapper padding={'py-[50px] md:py-[50px]'}>
            {data.HeroNumbers && (
              <motion.section
                id="status-numbers"
                className="flex flex-col md:flex-row justify-center items-center w-full gap-10 md:gap-20 px-90p"
                initial={{ y: 60, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                <div className="flex flex-col md:flex-row md:justify-center gap-[64px] w-full">
                  {data.HeroNumbers[0].number.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center text-center w-full"
                    >
                      <h6 className="n-heading text-white break-words text-center">
                        {item.value}
                      </h6>
                      <p className="font-[Poppins] text-[20px] font-normal leading-[36px] tracking-[1px] text-center n-paragraph-title text-white">
                        {item.title}
                      </p>
                    </div>
                  ))}
                </div>
              </motion.section>
            )}
          </GradientWrapper>
          <Wrapper>
            <PortfolioDataLayer testemonials={data.Testemonial} />
          </Wrapper>
        </div>
      </PageLayout>
    );
  } else {
    return <></>;
  }
}
