import React from 'react';

const CategoryNugget = ({ data }) => {
  return (
    <div className="rounded-full border-[1px] border-dg-secondary text-dg-secondary text-p px-[18px]">
      {data}
    </div>
  );
};

export default CategoryNugget;
