import React, { Fragment } from 'react';
import CareerCard from './CareerCard';
import { useState } from 'react';
import useComponentVisible from './../../hooks/useClickOutside';
import ExpandedCard from './ExpandedCard';
import { useContext } from 'react';
import { UIContext } from './../../context/UIContextProvider';

const JobCardsWrapper = ({ data }) => {
  const { jobForm, setJobForm } = useContext(UIContext);
  
  const [expandedCard, setExpandedCard] = useState({});

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  return (
    <section id="jobs" className="flex items-center justify-center max-w-custom m-auto">
      <div className="w-full flex flex-col md:flex-row gap-8">
        {isComponentVisible ? (
          <ExpandedCard
            card={expandedCard}
            setExpanded={setIsComponentVisible}
            setExpandedCard={setExpandedCard}
            forwarderdRef={ref}
            jobForm={jobForm}
            setJobForm={setJobForm}
          ></ExpandedCard>
        ) : (
          <div className="flex flex-col md:flex-row justify-center items-center w-full gap-8">
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <Fragment key={index}>
                  <CareerCard
                    card={item.attributes}
                    setExpanded={setIsComponentVisible}
                    setExpandedCard={setExpandedCard}
                  />
                </Fragment>
              ))}
          </div>
        )}
      </div>    
    </section>
  );
};

export default JobCardsWrapper;
