const api_url = process.env.REACT_APP_API_URL;

export const strapiApiBuilder = (page, stringArray, filter) => {
  const api = `${api_url}/api/${page}`;
  let query = '';
  stringArray.map((field, index) => {
    if (index === 0) query += `?populate[${index}]=${field}`;
    else {
      query += `&populate[${index}]=${field}`;
    }
  });
  return api + query + filter;
};
