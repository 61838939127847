import PropTypes from 'prop-types';

export default function HighlighedText(props) {
  return (
    <div className="text-dg-primary-400 text-center text-italic-title italic font-light rounded-full w-fit px-8 py-4">{props.text}</div>
  )
}

HighlighedText.propTypes = {
    text: PropTypes.string,
}
