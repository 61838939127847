import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ heading, subheading, left, color, pb }) => {
  return (
    <div
      className={
        'flex flex-col gap-8p' +
        (left ? ' text-left items-start' : ' text-center items-center') +
        (color ? color : '') +
        (pb ? ' pb-2' : ' ')
      }
    >
      <h6 className={'subheading ' + (left ? ' text-left' : ' text-center')}>
        {subheading}
      </h6>
      <h1 className={'heading text-inherit' + (left ? ' text-left' : ' text-center')}>
        {heading}
      </h1>
    </div>
  );
};

PageTitle.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  left: PropTypes.bool,
  color: PropTypes.bool,
};

export default PageTitle;
