import React, { useRef, useContext, useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { motion } from 'framer-motion';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactGA from 'react-ga4';
import TagInput from '../TagInput';
import { ClientFormContext } from '../../context';

import { sendEmail } from '../../utils/sendEmail';

const validationSchema = Yup.object({
  subject: Yup.string().min(2).max(50).required('Subject is Required'),
  email: Yup.string().email('Invalid email format').required('Email is Required'),
  firstName: Yup.string().min(2).max(50).required('First Name is Required'),
  lastName: Yup.string().min(2).max(50).required('Last Name is Required'),
  description: Yup.string().trim().min(2).required('Description is Required'),
});

const ClientForm = ({ mg, img, cta }) => {
  const [btnText, setBtnText] = useState(cta);
  const { clientForm, setClientForm } = useContext(ClientFormContext);
  const [message, setMessage] = useState({ show: false, type: null, text: '' });
  const captchaRef = useRef(null);

  useEffect(() => {
    setBtnText(cta);
  }, [cta]);

  const handleChange = event => {
    const { name, value } = event.target;
    setClientForm(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async values => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (!token) {
      setMessage({
        show: true,
        type: 'error',
        text: 'Please fill reCAPTCHA and try again. Thank you!',
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CAPTCHA_API}/verify-token`,
        { token },
      );

      if (response.data.data.success) {
        let emailObject = {
          to: process.env.REACT_APP_MAILING_RECEIVER,
          subject: values.subject,
          isBodyHtml: true,
          body: `<p>From: ${values.firstName} ${values.lastName} <${values.email}</p><p>${values.description}</p>`,
          priority: 1,
        };
        await sendEmail(emailObject).then(
          setMessage({
            show: true,
            type: 'success',
            text: 'Submission Successful! Thank you!',
          }),
        );
      } else {
        setMessage({
          show: true,
          type: 'error',
          text: 'Please fill reCAPTCHA and try again. Thank you!',
        });
      }
    } catch (error) {
      console.error(error);
      setMessage({
        show: true,
        type: 'error',
        text: 'Something went wrong! Please try again later.',
      });
    }

    ReactGA.event('contact', { category: 'Contact', action: 'Business Inquiry' });
  };

  return (
    <div className="mt-10 sm:mt-0 mx-auto">
      <div className="md:grid md:grid-cols-2 md:gap-6">
        <motion.div
          className="mt-5 md:mt-0 md:col-span-1"
          initial={{ x: -60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -60, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <Formik
            initialValues={clientForm}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <div className="sm:rounded-md py-2 sm:py-6">
                  <TagInput changeFormHandler={handleChange} props={props} />
                  <div className="py-1">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      onBlur={handleChange}
                      value={props.values.firstName}
                      onChange={props.handleChange}
                      autoComplete="given-name"
                      className="mt-1 block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-sm text-right text-red-600"
                    />
                  </div>
                  <div className="py-1">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={props.values.lastName}
                      onChange={props.handleChange}
                      onBlur={handleChange}
                      autoComplete="family-name"
                      className="mt-1 block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-sm text-right text-red-600"
                    />
                  </div>
                  <div className="py-1">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={handleChange}
                      autoComplete="email"
                      className="mt-1 block w-full shadow-sm sm:text-sm border-dg-primary-600 rounded-md transition duration-200"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-sm text-right text-red-600"
                    />
                  </div>
                  <div className="py-1">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                    >
                      Description
                    </label>
                    <textarea
                      placeholder="Describe your question or proposition..."
                      className="resize-y min-h-12 h-32 rounded-md mt-1 block w-full shadow-sm sm:text-sm border-dg-primary-600 transition duration-200"
                      name="description"
                      id="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={handleChange}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-sm text-right text-red-600"
                    />
                  </div>
                  <div className="items-center justify-end flex py-1">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      ref={captchaRef}
                    />
                  </div>
                  <div className="py-3 text-right">
                    <button
                      type="submit"
                      className="btn btn_primary transition-all inline-flex justify-center py-4 px-14 border border-transparent shadow-md text-sm font-semibold rounded-xl text-white bg-dg-primary-600 hover:bg-dg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dg-primary-600"
                    >
                      {btnText}
                    </button>
                  </div>
                  {message.show && (
                    <div
                      className={`text-sm text-right ${
                        message.type === 'success' && 'text-dg-primary-900'
                      } ${message.type === 'error' && 'text-[red]'}`}
                    >
                      {message.text}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </motion.div>
        <motion.div
          className="mt-5 md:mt-0 md:col-span-1 flex items-center"
          initial={{ x: 60, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 60, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <img
            src={img?.data?.attributes?.url}
            alt={img?.data?.attributes?.alternativeText}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default ClientForm;
