"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGhostSlideWidth = void 0;
var getGhostSlideWidth = function (sliderContext) {
    var alignLastSlide = sliderContext.alignLastSlide, slidesToShow = sliderContext.slidesToShow, slideWidth = sliderContext.slideWidth, scrollOffset = sliderContext.scrollOffset;
    var ghostSlideWidth = '';
    if (alignLastSlide !== undefined) {
        // NOTE: support arbitrary number value to use as the last slide, negative values being from track-right
        if (typeof alignLastSlide === 'number') {
            if (alignLastSlide < 0) {
                ghostSlideWidth = "".concat(alignLastSlide * -1, "px");
            }
            if (alignLastSlide > 0) {
                ghostSlideWidth = "calc((".concat(slideWidth, " * ").concat(slidesToShow - 1, ") - ").concat(alignLastSlide, "px)");
            }
        }
        if (typeof alignLastSlide === 'string') {
            switch (alignLastSlide) {
                case 'trackLeft': {
                    ghostSlideWidth = "calc(".concat(slideWidth, " * ").concat(slidesToShow - 1, ")");
                    break;
                }
                case 'offsetLeft': {
                    ghostSlideWidth = "calc((".concat(slideWidth, " * ").concat(slidesToShow - 1, ") - ").concat(scrollOffset, "px)");
                    break;
                }
                default: {
                    // NOTE: this is is similar to how the "number" type works, but with css strings instead
                    // first, we need to determine if the first character is a dash, which would indicate a negative value and begin from track-right
                    // then we use the value directly in our CSS string
                    var alignmentAsNumber = Number.parseInt(alignLastSlide, 10);
                    if (alignmentAsNumber < 0) {
                        ghostSlideWidth = "".concat(alignmentAsNumber * -1, "px");
                    }
                    if (alignmentAsNumber > 0) {
                        ghostSlideWidth = "calc((".concat(slideWidth, " * ").concat(slidesToShow - 1, ") - ").concat(alignLastSlide, ")");
                    }
                    break;
                }
            }
        }
    }
    return ghostSlideWidth;
};
exports.getGhostSlideWidth = getGhostSlideWidth;
