import React from 'react';

const Quote = ({data, color, larger}) => {
  return (
    <blockquote className={('p-4 my-4 border-l-8') + (typeof color !== "undefined" ? "  border-dg-primary-900 bg-white dark:border-gray-500 dark:bg-gray-800 text-dg-primary-600" : "  border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800")}>
      <p className={'italic text-xl font-medium leading-relaxed dark:text-white' + (typeof larger !== "undefined" ? " md:text-[32px]" : " md:text-xl")}>
        "{data}"
      </p>
    </blockquote>
  );
};

export default Quote;
