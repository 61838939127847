"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAutoplay = void 0;
var react_1 = require("react");
var useAutoplay = function (args) {
    var isPaused = args.isPaused, enable = args.enable, isFullyScrolled = args.isFullyScrolled, autoplaySpeed = args.autoplaySpeed, dispatchSliderState = args.dispatchSliderState;
    var animationRef = (0, react_1.useRef)(null);
    var autoplayTimer = (0, react_1.useRef)();
    var startAutoplay = (0, react_1.useCallback)(function () {
        autoplayTimer.current = setInterval(function () {
            if (animationRef.current)
                cancelAnimationFrame(animationRef.current);
            animationRef.current = requestAnimationFrame(function () {
                dispatchSliderState({
                    type: 'GO_TO_NEXT_SLIDE',
                    payload: {
                        loop: true,
                        isFullyScrolled: isFullyScrolled,
                    },
                });
            });
        }, autoplaySpeed);
    }, [
        isFullyScrolled,
        autoplaySpeed,
        dispatchSliderState
    ]);
    var stopAutoplay = (0, react_1.useCallback)(function () {
        var autoPlayTimerID = autoplayTimer.current;
        if (autoPlayTimerID)
            clearInterval(autoPlayTimerID);
    }, []);
    (0, react_1.useEffect)(function () {
        if (!isPaused && enable)
            startAutoplay();
        if (isPaused || !enable)
            stopAutoplay();
        return function () { return stopAutoplay(); };
    }, [
        isPaused,
        enable,
        startAutoplay,
        stopAutoplay,
    ]);
    (0, react_1.useEffect)(function () {
        (function () {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
            if (autoplayTimer.current) {
                clearTimeout(autoplayTimer.current);
            }
        });
    }, []);
    return null;
};
exports.useAutoplay = useAutoplay;
