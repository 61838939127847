import React from 'react'
import JobCardsWrapper from '../components/CareerCardsTemplates/JobCardsWrapper'
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import useDataApi from '../hooks/useDataApi';
import useFetchCollections from '../hooks/useFetchCollections';

const api_url = process.env.REACT_APP_API_URL;

const strapiPopulate = [
  'icon',
  'keys',
  'reqs',
  'offers',
];

const JobsDataLayer = () => {

    
  const strapi = strapiApiBuilder('jobs', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useFetchCollections(strapi);


  return (
     <JobCardsWrapper data={data} />
  )
}

export default JobsDataLayer