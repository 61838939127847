import { Popover, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../../App.css';
import logo from '../../assets/logos/LogoFull.svg';
import whiteLogo from '../../assets/logos/LogoFullWhite.svg';
import Wrapper from '../../layout/Wrapper';
import menuIcon from './../../assets/icons/menu.svg';

export default function Navigation({ links, scrollToView, activeLinks }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAtTop, setIsAtTop] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);

  function handleLogo() {
    navigate('/');
  }

  const handleDropdownEnter = index => {
    setOpenDropdown(index);
  };

  const handleDropdownLeave = () => {
    setOpenDropdown(null);
  };

  const checkUrl = event => {
    if (location.pathname === '/') scrollToView(event);
    else navigate('/contact');
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Popover
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        (isAtTop && location.pathname === '/') ||
        (isAtTop && location.pathname.includes('case-studies'))
          ? 'bg-transparent'
          : 'bg-white shadow-lg'
      }`}
    >
      <Wrapper>
        <div
          className={`flex justify-between items-center transition-all duration-300 ${
            isAtTop ? 'py-6' : 'py-3'
          }`}
        >
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="#">
              <span className="sr-only">Diligent</span>
              <img
                className="h-10 w-auto"
                src={isAtTop && location.pathname === '/' ? whiteLogo : logo}
                alt="Diligent logo"
                onClick={handleLogo}
              />
            </a>
          </div>
          <div className="mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-dg-primary-900 focus:outline-none">
              <img src={menuIcon} alt="Menu" className="h-[30px]" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:flex space-x-8 lg:space-x-10 items-center"
          >
            {links.map((item, index) =>
              item.drop && item.drop.length > 0 ? (
                <div
                  key={index}
                  onMouseEnter={() => handleDropdownEnter(index)}
                  onMouseLeave={handleDropdownLeave}
                >
                  <p
                    className={`flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium rounded ${
                      isAtTop && location.pathname === '/' ? 'text-[white]' : 'text-black'
                    } hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900`}
                  >
                    {item.title}
                    <svg
                      className="w-5 h-5 ml-1"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </p>
                  {openDropdown === index && (
                    <div
                      className={
                        'absolute z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow min-w-[150px]'
                      }
                    >
                      <ul className="py-4 px-4 text-sm text-gray-500">
                        {item.drop.map((item2, index2) => (
                          <li key={index2} className="mb-2 last:mb-0">
                            <a
                              href={item2.path}
                              className="text-p font-medium text-gray-500 hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900 capitalize whitespace-nowrap py-2 px-3"
                            >
                              {item2.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <NavLink
                  key={index}
                  to={item.path}
                  className={({ isActive }) =>
                    `text-p font-medium hover:text-dg-primary-900 focus:text-dg-primary-900 active:text-dg-primary-900 capitalize ${
                      isAtTop && location.pathname === '/'
                        ? 'text-[white]'
                        : activeLinks(isActive)
                    }`
                  }
                >
                  {item.title}
                </NavLink>
              ),
            )}
            <div onClick={checkUrl} className="contact-us-link text-p">
              Contact
            </div>
          </Popover.Group>
        </div>
      </Wrapper>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          static
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-10 w-auto cursor-pointer"
                      src={logo}
                      alt="Diligent"
                      onClick={handleLogo}
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center transition-all text-dg-primary-900 hover:text-white hover:bg-dg-primary-900 focus:outline-none">
                      <p className="font-semibold">x</p>
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-4">
                  <nav className="grid">
                    {links.map((item, index) => {
                      const showMainItem = item.mobile;

                      const showDropdownItems =
                        item.drop && item.drop.some(dropItem => dropItem.mobile);

                      if (showMainItem || showDropdownItems) {
                        return (
                          <div key={index}>
                            {showMainItem && (
                              <NavLink
                                onClick={() => close()}
                                to={item.path}
                                className="text-p p-3 flex justify-center items-center rounded-md transition-all hover:bg-dg-primary-900 hover:text-white mb-[4px]"
                              >
                                <span className="ml-3 text-base font-medium text-inherit">
                                  {item.title}
                                </span>
                              </NavLink>
                            )}
                            {showDropdownItems &&
                              item.drop.map((item2, index2) =>
                                item2.mobile ? (
                                  <NavLink
                                    onClick={() => close()}
                                    key={index2}
                                    to={item2.path}
                                    className="text-p p-3 flex justify-center items-center rounded-md transition-all hover:bg-dg-primary-900 hover:text-white mb-[4px]"
                                  >
                                    <span className="ml-3 text-base font-medium text-inherit">
                                      {item2.title}
                                    </span>
                                  </NavLink>
                                ) : null,
                              )}
                          </div>
                        );
                      }
                      return null;
                    })}
                    <a
                      href="#contact"
                      onClick={event => {
                        close();
                        checkUrl(event);
                      }}
                      className="contact-us-link flex justify-center items-center text-p"
                    >
                      <span className="ml-3 text-base font-medium text-inherit">
                        Contact
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};
