export const sendEmail = async emailData => {
  const { to, subject, body } = emailData;

  if (!to) {
    throw new Error("Field 'to' is required.");
  }
  if (!subject) {
    throw new Error("Field 'subject' is required.");
  }
  if (!body) {
    throw new Error("Field 'body' is required.");
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_MAILING_URL}/SendEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ApiKey: process.env.REACT_APP_MAILING_API_KEY,
      },
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Email sent successfully:', data);
    return data;
  } catch (error) {
    console.error('Error sending email:', error);
  }
};
