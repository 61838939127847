import { useEffect } from 'react';
import JobsDataLayer from '../api/JobsDataLayer';
import Animation_Diligent from '../assets/animation_diligent.webm';
import HRProcess from '../components/HRProcess';
import ActionCard from '../components/shared/ActionCard';
import CardValues from '../components/shared/CardValues';
import PageTitle from '../components/shared/PageTitle';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import ReactHelmet from './../components/shared/ReactHelmet';
import VideoComponent from './../components/shared/VideoComponent';
import useAnalytics from './../hooks/useAnalytics';
import useDataApi from './../hooks/useDataApi';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const strapiPopulate = [
  'diligent_life',
  'diligent_life.heading_section',
  'diligent_life.CTO',
  'diligent_life.media_text.media',
  'selection_process.heading',
  'selection_process.steps.media',
  'positions_heading',
  'positions_card.Image',
  'benefits_heading',
  'benefits_card.media',
  'Cards.Card3.Image',
  'ActionCard',
  'Video',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

export default function Careers({ forwardedRef }) {
  const strapi = strapiApiBuilder('n-careerspage', strapiPopulate, '');

  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);

  useEffect(() => {
    document.title = 'Careers';
  }, []);

  useAnalytics('Careers');

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <PageLayout>
      {data && data.SEO && <ReactHelmet seo={data.SEO} />}
      {data ? (
        <div className="bg-white dark:bg-dg-primary-1700 w-full pt-[50px] md:pt-[50px] overflow-hidden">
          {/* Diligent Life */}
          <Wrapper padding={' py-90p'}>
            <PageTitle
              left
              heading={data.diligent_life.heading_section.heading}
              subheading={data.diligent_life.heading_section.subheading}
            />

            <div className="mt-20p">
              <div>
                <p className="paragraph">
                  {data.diligent_life.heading_section.paragraph}
                </p>

                {/* Video Frame */}
                {data && data.Video && data.Video.data && (
                  <VideoComponent
                    source={data.Video.data.attributes.url}
                    alt={data.Video.data.attributes.alternativeText}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between lg:justify-center gap-8">
              <p className="paragraph w-full lg:w-3/4">
                {data.diligent_life.CTO.cto_paragraph}
              </p>
            </div>
          </Wrapper>

          {/* Selection Process */}
          <Wrapper bg padding={' py-90p'}>
            <PageTitle
              left
              heading={data.selection_process.heading.heading}
              subheading={data.selection_process.heading.subheading}
            />
            <HRProcess data={data} />
          </Wrapper>

          {/* Open Positions */}
          <Wrapper padding={' py-90p'}>
            <PageTitle
              heading={data.positions_heading.heading}
              subheading={data.positions_heading.subheading}
              left
              color
            />
            <div className="pt-72p">
              <JobsDataLayer />
            </div>
          </Wrapper>

          {/* Benefits Section */}
          <Wrapper bg padding={' py-90p'}>
            <section id="values" className="flex flex-row items-center justify-center">
              <div className="my-8 flex flex-col justify-center items-start w-full max-w-custom m-auto">
                <h2 className="heading text-dg-secondary">
                  {data.benefits_heading.heading}
                </h2>
                <div className="grid grid-cols-1 gap-16 w-full mt-8">
                  {data.benefits_card.map((item, index) => (
                    <CardValues
                      key={index}
                      image={item.media.data.attributes.url}
                      heading={item.heading}
                      paragraph={item.paragraph}
                    />
                  ))}
                </div>
              </div>
            </section>
          </Wrapper>

          {/* Action Card */}
          {data ? (
            <Wrapper>
              <div className="my-90p">
                <ActionCard
                  title={data.ActionCard.title}
                  text={data.ActionCard.paragraph}
                  btn1={data.ActionCard.ButtonPrimary}
                  btn2={data.ActionCard.ButtonSecondary}
                  link1={'/contact'}
                  link2={'/about'}
                />
              </div>
            </Wrapper>
          ) : null}
        </div>
      ) : null}
    </PageLayout>
  );
}
