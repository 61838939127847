import { motion } from 'framer-motion';
import React from 'react';
import Animation_Diligent from '../assets/animation_diligent.webm';
import PricingCard from '../components/PricingCard';
import GradientWrapper from '../components/shared/GradientWrapper';
import OrbitOnScroll from '../components/shared/graphics/OrbitOnScroll';
import useDataApi from '../hooks/useDataApi';
import useAnalytics from './../hooks/useAnalytics';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const strapiPopulate = ['pageTitle', 'pageSubtitle', 'cards', 'cards.bulletPoints'];

const Pricing = () => {
  const strapi = strapiApiBuilder('pricing', strapiPopulate, '');

  const [{ data, isLoading }] = useDataApi(strapi);

  useAnalytics('Pricing');

  React.useEffect(() => {
    document.title = 'Pricing';
  }, []);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else if (data && !isLoading) {
    return (
      <GradientWrapper padding={'py-32p md:py-90p'}>
        <div className="relative w-full">
          <div className="text-center pt-24">
            <h2 className="font-[Poppins] text-[12px] font-semibold leading-[28px] tracking-[2px] uppercase text-[#FFFFFF]">
              {data.pageSubtitle}
            </h2>
            <h1 className="font-[Poppins] text-[50px] font-bold leading-[55px] tracking-[1px] text-[#FFFFFF]">
              {data.pageTitle}
            </h1>
          </div>
          <motion.section
            id="pricing"
            className="flex flex-col items-center relative pt-64p lg:pt-82p pb-[144px] z-10"
          >
            <div className="absolute inset-0 flex justify-center items-center z-0 ml-[-200px] hidden lg:flex">
              <OrbitOnScroll />
            </div>
            <div className="flex flex-col justify-center items-center gap-90p z-10">
              <div className="flex flex-col lg:flex-row gap-32p h-fit sm:px-64p lg:px-0">
                <motion.div
                  initial={{ opacity: 0, scale: 1.1, y: 90 }}
                  whileInView={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ delay: 0, default: { duration: 0.45 } }}
                >
                  <PricingCard
                    title={data.cards[0].title}
                    subtitle={data.cards[0].subtitle}
                    buttonTitle={data.cards[0].buttonTitle}
                    bulletPoints={data.cards[0].bulletPoints}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 1.1, y: 120 }}
                  whileInView={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ delay: 0.27, default: { duration: 0.45 } }}
                >
                  <PricingCard
                    title={data.cards[1].title}
                    subtitle={data.cards[1].subtitle}
                    buttonTitle={data.cards[1].buttonTitle}
                    bulletPoints={data.cards[1].bulletPoints}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 1.1, y: 140 }}
                  whileInView={{ opacity: 1, scale: 1, y: 0 }}
                  transition={{ delay: 0.54, default: { duration: 0.45 } }}
                >
                  <PricingCard
                    title={data.cards[2].title}
                    subtitle={data.cards[2].subtitle}
                    buttonTitle={data.cards[2].buttonTitle}
                    bulletPoints={data.cards[2].bulletPoints}
                  />
                </motion.div>
              </div>
            </div>
          </motion.section>
        </div>
      </GradientWrapper>
    );
  } else {
    return <></>;
  }
};

export default Pricing;
