import React from 'react';
import Animation_Diligent from './../../assets/animation_diligent.webm';

const Loader = () => {
  return (
    <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
      <video id="animation" width="540" height="540" autoPlay muted loop>
        <source src={Animation_Diligent} type="video/webm" />
        Loading...
      </video>
    </div>
  );
};
export default Loader;
