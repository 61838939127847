"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMarquee = void 0;
var react_1 = require("react");
var useMarquee = function (args) {
    var sliderTrackRef = args.sliderTrackRef, isFullyScrolled = args.isFullyScrolled, enable = args.enable, isPaused = args.isPaused, marqueeSpeed = args.marqueeSpeed // NOTE: this is technically a requested frame rate
    ;
    var animationRef = (0, react_1.useRef)();
    var prevTimestamp = (0, react_1.useRef)();
    var timeSincePrev = (0, react_1.useRef)(0);
    var scroll = (0, react_1.useCallback)(function (timestamp) {
        if (enable && !isPaused) {
            var elapsed = prevTimestamp.current ? timestamp - prevTimestamp.current : 0;
            prevTimestamp.current = timestamp;
            timeSincePrev.current += elapsed;
            if (!marqueeSpeed || (marqueeSpeed && timeSincePrev.current > marqueeSpeed)) {
                if (sliderTrackRef.current) {
                    if (!isFullyScrolled)
                        sliderTrackRef.current.scrollLeft = sliderTrackRef.current.scrollLeft + 1;
                    else
                        sliderTrackRef.current.scrollLeft = 0;
                }
                timeSincePrev.current = 0;
            }
            // NOTE: recursively request animation to create a loop at ~60fps
            animationRef.current = window.requestAnimationFrame(scroll);
        }
    }, [
        sliderTrackRef,
        enable,
        isPaused,
        marqueeSpeed,
        isFullyScrolled
    ]);
    (0, react_1.useEffect)(function () {
        if (enable && !isPaused)
            animationRef.current = window.requestAnimationFrame(scroll);
        if (isPaused && animationRef.current)
            cancelAnimationFrame(animationRef.current);
        return function () {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [
        scroll,
        enable,
        isPaused
    ]);
    return null;
};
exports.useMarquee = useMarquee;
