import { Tab } from '@headlessui/react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import Animation_Diligent from '../../assets/animation_diligent.webm';
import { ClientFormContext } from '../../context';
import Wrapper from '../../layout/Wrapper';
import ClientForm from './ClientForm';
import JobForm from './JobForm';
import PageTitle from './PageTitle';
import OrbitOnClick from './graphics/OrbitOnClick';

export default function Contact({ job, business }) {
  const [tab, setTab] = useState(0);

  function handleTabClick(event) {
    event.preventDefault();
  }
  const clientContext = useContext(ClientFormContext);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function handleTab(tabIndex) {
    setTab(tabIndex);
  }

  const initialClientValues = clientContext ?? {
    tag: '',
    subject: '',
    firstName: '',
    lastName: '',
    email: '',
    description: '',
  };

  const api_url = process.env.REACT_APP_API_URL;

  const [clientForm, setClientForm] = useState(initialClientValues);

  const [cntCareers, setCntCareers] = useState('');
  const [isLoaded, setIsLoaded] = useState('');
  useEffect(async () => {
    var vid = document.getElementById('animation');
    vid.playbackRate = 2;
    axios
      .get(
        `${api_url}/api/w-home-page?populate[0]=BusinessInquiry.image&populate[1]=ApplyPosition.image`,
      )
      .then(res => {
        setCntCareers(res.data.data.attributes);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
        setIsLoaded(false);
      });
  }, []);

  if (!isLoaded) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  }

  return (
    <Wrapper bg>
      <div className="absolute top-40 md:top-8 right-11 md:right-12 z-10">
        <OrbitOnClick tab={tab} />
      </div>
      <div className="py-16 relative">
        <div className="flex justify-end flex-col">
          <Tab.Group defaultIndex={tab} onChange={index => handleTab(index)}>
            <div
              className="flex flex-col md:flex-row items-start md:items-baseline md:justify-between"
              onMouseDown={handleTabClick}
            >
              {tab === 0 && (
                <PageTitle
                  left
                  heading={cntCareers.BusinessInquiry.title}
                  subheading={cntCareers.BusinessInquiry.subtitle}
                />
              )}
              {tab === 1 && (
                <PageTitle
                  left
                  heading={cntCareers.ApplyPosition.title}
                  subheading={cntCareers.ApplyPosition.subtitle}
                />
              )}
              <Tab.List className="flex flex-row items-center md:flex-col lg:flex-row h-fit w-fit max-w-max md:ml-auto z-20 p-1 min-h-12 space-x-1 bg-dg-primary-400 rounded-xl my-4 align-middle">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'h-fit w-fit py-2 px-4 text-sm leading-5 font-medium text-dg-primary-900 rounded-lg',
                      'focus:outline-none ',
                      selected
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                    )
                  }
                >
                  Business Inquiry
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      'h-fit w-fit py-2 px-4 text-sm leading-5 font-medium text-dg-primary-900 rounded-lg',
                      'focus:outline-none',
                      selected
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white',
                    )
                  }
                >
                  Apply for a position
                </Tab>
              </Tab.List>
            </div>

            <Tab.Panels className="w-full mt-2 mx-auto">
              <Tab.Panel className={classNames('py-3', 'outline-none')}>
                <ClientFormContext.Provider value={{ clientForm, setClientForm }}>
                  <ClientForm cta={business.cta} img={business.image} />
                </ClientFormContext.Provider>
              </Tab.Panel>
              <Tab.Panel className={classNames('py-3', 'outline-none')}>
                <JobForm cta={job.cta} img={job.image} cntCareers={cntCareers} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Wrapper>
  );
}

Contact.propTypes = {
  setTab: PropTypes.func,
  tabTitle: PropTypes.string,
  setTabTitle: PropTypes.func,
  defaultIndex: PropTypes.number,
  defaultPositionSelection: PropTypes.string,
};
