import { useEffect, useRef, useState } from 'react';
import TestimonialsDataLayer from '../api/TestimonialsDataLayer';
import Animation_Diligent from '../assets/animation_diligent.webm';
import landingVideo from '../assets/landing-video.mp4';
import MapDilig from '../components/Map';
import NewLanding from '../components/NewLanding';
import PortfolioSection from '../components/PortfolioSection';
import ProcessSection from '../components/ProcessSection';
import TechStack from '../components/TechStack';
import WhySection from '../components/WhySection';
import Contact from '../components/shared/Contact';
import ReactHelmet from '../components/shared/ReactHelmet';
import useAnalytics from '../hooks/useAnalytics';
import useDataApi from '../hooks/useDataApi';
import PageLayout from '../layout/PageLayout';
import '../styles/buttons.css';
import '../styles/cards.css';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const strapiPopulate = [
  'Heading',
  'Heading.subtitle',
  'Heading.title',
  'WhyUsHeading',
  'Cards',
  'Cards.Card1',
  'Cards.Card1.Image',
  'Cards.Card2',
  'Cards.Card2.Image',
  'Cards.Card3',
  'Cards.Card3.Image',
  'HeroNumbers',
  'HeroNumbers.number',
  'ProcessHeading',
  'ProcessImage',
  'TechStackHeading',
  'ProcessMobileImg',
  'ProcessMobileImg.Image',
  'CaseStudies',
  'Map',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
  'BusinessInquiry',
  'BusinessInquiry.image',
  'ApplyPosition',
  'ApplyPosition.image',
  'LandingVideo',
];

export default function Home({ forwardedRef }) {
  const strapi = strapiApiBuilder('w-home-page', strapiPopulate, '');
  const [{ data, isLoading }] = useDataApi(strapi);
  const [whySectionMargin, setWhySectionMargin] = useState(0);
  const absoluteDivRef = useRef(null);

  useAnalytics('Home');

  const handleMargins = () => {
    if (absoluteDivRef.current) {
      setWhySectionMargin(absoluteDivRef.current.clientHeight - 150);
    }
  };

  useEffect(() => {
    handleMargins();
  }, [data]);

  useEffect(() => {
    document.title = 'Diligent Software';
  }, []);

  window.addEventListener('resize', handleMargins);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else {
    return (
      <PageLayout>
        {data && data.SEO ? <ReactHelmet seo={data.SEO} /> : null}
        <div className="bg-white dark:bg-dg-primary-1700 w-full pt-32 overflow-hidden">
          {data ? (
            <div
              ref={absoluteDivRef}
              className="absolute top-0 left-0 w-full h-screen overflow-hidden"
            >
              <video
                src={landingVideo}
                autoPlay
                loop
                muted
                className="absolute top-0 left-0 w-full h-full object-cover filter brightness-75"
              />
              <NewLanding
                heading={data.Heading}
                numbers={data.HeroNumbers.number}
                paragraph={data.paragraph}
                button={data.button}
              />
            </div>
          ) : null}

          {data ? (
            <div style={{ marginTop: whySectionMargin }}>
              <WhySection
                heading={data.WhyUsHeading}
                p1={data.WhyUsParagraph1}
                p2={data.WhyUsParagraph2}
                cards={data.Cards}
              />
            </div>
          ) : null}

          {data ? (
            <ProcessSection
              heading={data.ProcessHeading}
              btn={data.ProcessCTA}
              image={data.ProcessImage}
              mobileImages={data.ProcessMobileImg.Image}
            />
          ) : null}

          {data ? (
            <TechStack heading={data.TechStackHeading} btn={data.TechStackCTA} />
          ) : null}

          {data ? <TestimonialsDataLayer /> : null}

          {data ? <PortfolioSection heading={data.CaseStudies} /> : null}

          {data ? (
            <section id="contact" ref={forwardedRef}>
              <Contact job={data.ApplyPosition} business={data.BusinessInquiry} />
            </section>
          ) : null}

          {data ? <MapDilig heading={data.Map} /> : null}
        </div>
      </PageLayout>
    );
  }
}
