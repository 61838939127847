import '../../App.css';
import Wrapper from '../../layout/Wrapper';
import Cookie from './../../assets/Cookie.png';

const cookie_p =
  'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.';

const CookieBanner = ({ handleAccept, handleDecline }) => {
  return (
    <Wrapper>
      <div className="flex flex-row items-start gap-8 xl:mr-0 mr-4 md:p-8 px-2 py-4 isolate max-w-banner fixed bg-dg-primary-75 shadow-custom z-50 bottom-0 rounded-2xl mb-3">
        <img
          src={Cookie}
          alt="Diligent Cookie"
          className="hidden md:block w-44 h-48 flex-none z-0 grow-0 order-none"
        />
        <div className="flex flex-col items-start p-0 gap-2.5 max-w-banner">
          <div className="flex flex-col items-start p-0 gap-4 max-w-banner ">
            <p className="md:text-title text-banner-mobile text-dark-gray font-secondary font-semibold">
              Our Website uses Cookies
            </p>
            <p className="flex items-center text-dark-gray font-secondary md:text-banner-p text-banner-p-mobile">
              {cookie_p}
            </p>
          </div>
          <div className="max-w-banner w-full flex items-center justify-end p-0 md:gap-2.5">
            <button
              onClick={handleDecline}
              className="flex items-start p-0 text-dg-primary-900 bg-dg-primary-75 font-semibold font-secondary md:px-64p px-42p md:py-20p py-15p md:w-max w-36 whitespace-nowrap"
            >
              DECLINE
            </button>
            <button
              onClick={handleAccept}
              className="btn-primary flex flex-row items-start md:px-64p px-42p md:py-20p py-15p md:w-max w-36 whitespace-nowrap"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CookieBanner;
