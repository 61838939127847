import PropTypes from 'prop-types';

export default function CardValues(props) {
  return (
    <div className="flex flex-col justify-center items-start text-left card-no-hover w-full">
      <div className="flex flex-row justify-start items-center">
        <img src={props.image} alt={props.alt} width={27} />
        <h3 className="h3-heading ml-2">{props.heading}</h3>
      </div>
      <p className="paragraph mt-2">{props.paragraph}</p>
    </div>
  );
}

CardValues.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  image: PropTypes.string,
};
