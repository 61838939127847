import PropTypes from 'prop-types';

import { ReactComponent as Facebook } from '../../assets/icons/footer/fb.svg';
import { ReactComponent as Instagram } from '../../assets/icons/footer/ig.svg';
import { ReactComponent as LinkedIn } from '../../assets/icons/footer/ln.svg';

export default function SocialMediaLinks(props) {
  return (
    <div className={'w-' + props.divWidth + ' grid grid-cols-3 gap-8 pt-8 z-40'}>
      <div className="social_link flex items-center justify-center lg:justify-end">
        <a
          href="https://www.facebook.com/DiligentSoftware/"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook
            className={
              'scale-' +
              props.iconSize +
              ' transition-all hover:scale-' + props.iconSizeHover + ' hover:cursor-pointer hover:transition-all'
            }
          />
        </a>
      </div>
      <div className="social_link flex items-center justify-center">
        <a
          href="https://www.instagram.com/diligent_software/"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram
            className={
              'scale-' +
              props.iconSize +
              ' transition-all hover:scale-' + props.iconSizeHover + ' hover:cursor-pointer hover:transition-all'
            }
          />
        </a>
      </div>
      <div className="social_link flex items-center justify-center lg:justify-start">
        <a
          href="https://linkedin.com/company/diligent-doo"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn
            className={
              'scale-' +
              props.iconSize +
              ' transition-all hover:scale-' + props.iconSizeHover + ' hover:cursor-pointer hover:transition-all'
            }
          />
        </a>
      </div>
    </div>
  );
}
