import About from './pages/About';
import Blog from './pages/Blog';
import Careers from './pages/Careers';
import ContactPage from './pages/ContactPage';
import Culture from './pages/Culture';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Pricing from './pages/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProcessPage from './pages/ProcessPage';
import StartupCorner from './pages/StartupCorner';
import WorkWithUs from './pages/WorkWithUs';

const routes = [
  {
    path: '/',
    component: <Home />,
    title: 'Diligent Software',
    exact: true,
    mobile: true,
  },
  {
    path: '/workwithus',
    component: <WorkWithUs />,
    title: 'Work with Us',
    exact: true,
    nav: true,
    mobile: false,
    drop: [
      {
        path: 'professional-service',
        component: <WorkWithUs />,
        title: 'Professional Service',
        exact: true,
        nav: true,
        mobile: true,
      },
      {
        path: '/process',
        component: <ProcessPage />,
        title: 'Process',
        exact: true,
        nav: true,
        mobile: true,
      },
      {
        path: '/about',
        component: <About />,
        title: 'About Us',
        exact: true,
        nav: true,
        mobile: true,
      },
    ],
  },
  {
    path: '/startup-corner',
    component: <StartupCorner />,
    title: 'Startup Corner',
    exact: true,
    nav: true,
    mobile: true,
  },
  {
    path: '/portfolio',
    component: <Portfolio />,
    title: 'Case Studies',
    exact: true,
    nav: true,
    mobile: true,
  },

  {
    path: '/careers',
    component: <Careers />,
    title: 'Careers',
    exact: true,
    nav: true,
    mobile: false,
    drop: [
      {
        path: '/careers',
        component: <Careers />,
        title: 'Careers',
        exact: true,
        nav: true,
        mobile: true,
      },
      {
        path: '/culture',
        component: <Culture />,
        title: 'Culture',
        exact: true,
        nav: true,
        mobile: true,
      },
    ],
  },
  {
    path: '/blog',
    component: <Blog />,
    title: 'Blog',
    exact: true,
    nav: true,
    mobile: true,
  },
  {
    path: '/pricing',
    component: <Pricing />,
    title: 'Pricing',
    exact: true,
    nav: true,
    mobile: true,
  },
  {
    path: '/contact',
    component: <ContactPage />,
    title: 'Contact Us',
    exact: true,
  },
  {
    path: '/privacypolicy',
    component: <PrivacyPolicy />,
    title: 'Privacy Policy',
    exact: true,
  },
];

export default routes;
