import React from 'react';
import { ReactComponent as TriangleSVG } from '../../../assets/TriangleBtn.svg';

export default function TriangleButton(props) {
  const classList = 'cursor-pointer';
  const clickFn = props.disabled ? null : props.onClick;

  const direction = () => {
    switch (props.direction) {
      case 'left':
        return '';
      case 'up':
        return 'rotate-90';
      case 'right':
        return 'rotate-180';
      case 'down':
        return '-rotate-90';
      default:
        return '';
    }
  };

  const directionClass = direction();
  return (
    <div onClick={clickFn} className={classList}>
      <TriangleSVG className={directionClass + (props.disabled ? ' bg-black' : '')} />
    </div>
  );
}
