import React, { useEffect, useState } from 'react';
import Animation_Diligent from '../assets/animation_diligent.webm';
import ProcessSlider from '../components/ProcessSlider';
import ActionCard from '../components/shared/ActionCard';
import PageTitle from '../components/shared/PageTitle';
import ProcessCard from '../components/shared/ProcessCard';
import ProcessCardsWrapper from '../components/shared/ProcessCardWrapper';
import useAnalytics from '../hooks/useAnalytics';
import useWindowSize from '../hooks/useWindowSize';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import { strapiApiBuilder } from '../utils/strapiApiBuilder';
import ReactHelmet from './../components/shared/ReactHelmet';
import useDataApi from './../hooks/useDataApi';

const strapiPopulate = [
  'WorkTogether',
  'Heading',
  'ProcessImage',
  'ProcessMobileImages',
  'Step',
  'Step.paragraphs',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

const ProcessPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  const strapi = strapiApiBuilder('processpage', strapiPopulate, '');

  const [{ data, isLoading }] = useDataApi(strapi);

  const windowInfo = useWindowSize();

  useEffect(() => {
    document.title = 'Process';
  }, []);

  useAnalytics('Process');

  useEffect(() => {
    if (windowInfo.width < 1000) setIsMobile(true);
    else setIsMobile(false);
  }, [windowInfo]);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else {
    return (
      <PageLayout>
        {data && data.SEO && <ReactHelmet seo={data.SEO} />}
        <div className="pt-32">
          <Wrapper>
            {data ? (
              <PageTitle
                left
                color
                heading={data.Heading.title}
                subheading={data.Heading.subtitle}
              />
            ) : null}

            <div className="relative mx-auto my-32p md:my-90p">
              {!isMobile ? (
                <div className="w-full">
                  {data ? (
                    <img
                      src={data.ProcessImage.data.attributes.url}
                      alt={data.ProcessImage.data.attributes.alternativeText}
                    ></img>
                  ) : null}
                </div>
              ) : (
                <div className="">
                  <ProcessSlider images={data.ProcessMobileImages.data} />
                </div>
              )}
            </div>
            {data ? <p className="my-90p">{data.paragraph}</p> : null}
          </Wrapper>
          <Wrapper bg padding={' py-90p'}>
            {data
              ? data.Step.length > 0 && (
                  <ProcessCardsWrapper>
                    {data.Step.map((item, index) => (
                      <ProcessCard
                        key={index}
                        id={index + 1}
                        title={item.title}
                        subtitle={item.subtitle}
                        text={item.paragraphs}
                        numeric
                      />
                    ))}
                  </ProcessCardsWrapper>
                )
              : null}
          </Wrapper>
          <Wrapper>
            <div className="my-90p">
              {data ? (
                <ActionCard
                  title={data.WorkTogether.title}
                  text={data.WorkTogether.paragraph}
                  btn1={data.WorkTogether.ButtonPrimary}
                  btn2={data.WorkTogether.ButtonSecondary}
                  link1={'/contact'}
                  link2={'/portfolio'}
                />
              ) : null}
            </div>
          </Wrapper>
        </div>
      </PageLayout>
    );
  }
};

export default ProcessPage;
