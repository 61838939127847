import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import Wrapper from '../layout/Wrapper';
import ProcessSlider from './ProcessSlider';
import PageTitle from './shared/PageTitle';

const ProcessSection = ({ image, imageAlt, heading, btn, mobileImages }) => {
  const [isMobile, setIsMobile] = useState(false);

  const windowInfo = useWindowSize();

  useEffect(() => {
    if (windowInfo.width < 1000) setIsMobile(true);
    else setIsMobile(false);
  }, [windowInfo]);

  const link = useNavigate();

  return (
    <Wrapper padding={' py-90p'} hideOverflow>
      <div className="w-full">
        {heading ? (
          <PageTitle heading={heading.title} subheading={heading.subtitle} color left />
        ) : null}
      </div>

      <div className="relative mx-auto my-32p md:my-90p">
        {!isMobile ? (
          <div className="w-full">
            {image ? (
              <img
                src={image.data.attributes.url}
                alt={image.data.attributes.alternativeText}
              ></img>
            ) : null}
          </div>
        ) : (
          <div className="">
            <ProcessSlider images={mobileImages.data} />
          </div>
        )}
      </div>
      <div className="flex justify-center w-full">
        <button className="btn-secondary" onClick={() => link('/process')}>
          {btn}
        </button>
      </div>
    </Wrapper>
  );
};

export default ProcessSection;
