import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import '../../styles/cards.css';
import { Fragment, useEffect } from 'react';

const api_url = process.env.REACT_APP_API_URL;

const ReactHelmet = ({ seo }) => {
  return (
    <Fragment>
      {seo && (
        <Helmet>
          <title>{seo.metaTitle}</title>
          <meta name="description" content={seo.metaDescription} />
          <link rel="canonical" href={seo.canonicalURL} />
          <meta name="keywords" content={seo.keywords} />
          <meta
            name="viewport"
            content={
              seo.metaViewport
                ? seo.metaViewport
                : 'width=device-width, height=device-height'
            }
          />

          <meta name="robots" content={seo.metaRobots} />

          <meta property="og:type" content="website" />
          <meta property="og:title" content={seo.metaTitle ? seo.metaTitle : 'website'} />
          <meta property="”og:url”" content={`"${seo.canonicalURL ? seo.canonicalURL : '' }"`} />
          <meta property="og:description" content={seo.metaDescription ? seo.metaDescription : ''} />
          <meta property="og:image:url" content={(seo.metaImage && seo.metaImage.data) ? seo.metaImage.data.attributes.url : ''} />

          {seo.metaSocial && seo.metaSocial.length > 0 && (
            <Fragment>
              <meta property="twitter:title" content={seo.metaSocial[1]?.title} />
              {seo.metaSocial[1]?.image?.data && (
                <meta
                  property="twitter:image"
                  content={`${seo.metaSocial[1]?.image?.data?.attributes?.url}`}
                />
              )}

              <meta
                property="twitter:description"
                content={seo.metaSocial[1]?.description}
              />
            </Fragment>
          )}
        </Helmet>
      )}
    </Fragment>
  );
};

export default ReactHelmet;
