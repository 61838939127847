import { motion } from 'framer-motion';
import React from 'react';
import Wrapper from '../layout/Wrapper';

const NewLanding = ({ heading, button, paragraph }) => {
  return (
    <div className="absolute top-[0] left-[0] w-full h-screen flex flex-col gap-90p pb-164p">
      <Wrapper>
        <motion.section
          id="landing"
          className="flex flex-col items-center justify-center h-screen"
          initial={{ y: 60, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <div className="relative z-10 flex flex-col lg:flex-row justify-between items-center gap-90p text-white">
            <div className="flex flex-col gap-8p text-[white]">
              <h1
                className="text-[white] heading w-2/3
"
              >
                {heading.title}
              </h1>
              <p
                className="text-[white] paragraph w-1/2
"
              >
                {paragraph}
              </p>
              <a
                href="#contact"
                className="btn-primary px-64p py-20p w-full lg:w-max whitespace-nowrap mt-12"
              >
                {button}
              </a>
            </div>
          </div>
        </motion.section>
      </Wrapper>
    </div>
  );
};

export default NewLanding;
