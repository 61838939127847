import React from 'react'
import PropTypes from 'prop-types'

const ProcessSliderWrapper = ({children}) => {
  return (
    <div className='relative mx-8'>
    {children}
</div>
  )
}

ProcessSliderWrapper.propTypes = {}

export default ProcessSliderWrapper