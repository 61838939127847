import React, { Fragment } from "react";
import Carousel from './base/index';



const FMCarousel = ({children}) => {

    return (
        <Carousel autoPlay={true} renderArrowLeft={()=>{}} renderArrowRight={()=>{}}>
        {React.Children.map(children, (item, i) => (
          <Fragment
            key={i}
          >{item}</Fragment>
        ))}
      </Carousel>
    )
}

export default FMCarousel;