export const sendEmailWithAttachment = async emailData => {
  const { to, subject, body, attachments } = emailData;

  if (!to) {
    throw new Error("Field 'to' is required.");
  }
  if (!subject) {
    throw new Error("Field 'subject' is required.");
  }
  if (!body) {
    throw new Error("Field 'body' is required.");
  }

  const formData = new FormData();

  formData.append('to', to);
  formData.append('subject', subject);
  formData.append('body', body);
  formData.append('isBodyHtml', emailData.isBodyHtml);
  formData.append('priority', emailData.priority);

  if (emailData.toMultiple && emailData.toMultiple.length > 0) {
    formData.append('toMultiple', emailData.toMultiple.join(','));
  }
  if (emailData.cc && emailData.cc.length > 0) {
    formData.append('cc', emailData.cc.join(','));
  }

  if (attachments && attachments.length > 0) {
    attachments.forEach(file => {
      formData.append('attachments', file);
    });
  }

  try {
    const response = await fetch(
      'https://emailservice.dilig.net/SendEmailWithAttachments',
      {
        method: 'POST',
        headers: {
          ApiKey: process.env.REACT_APP_MAILING_API_KEY,
        },
        body: formData,
      },
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Email sent successfully:', data);
    return data;
  } catch (error) {
    console.error('Error sending email:', error);
  }
};
