import propTypes from 'prop-types';

export default function WhyUsCard(props) {
  return (
    <div className="card-no-hover w-fit h-full flex flex-col text-center justify-between group">
      <img
        src={props.image}
        alt={props.alt}
        className="ml-auto mr-auto block w-2/5 bg-baby-blue text-dark-gray rounded-full"
      />
      <h4 className="mt-6 font-semibold text-title">{props.heading}</h4>
      <p className="mt-6 text-sm">{props.paragraph}</p>
    </div>
  );
}

WhyUsCard.propTypes = {
  image: propTypes.string,
  alt: propTypes.string,
  heading: propTypes.string,
  paragraph: propTypes.string,
};
