import { useEffect, useState } from "react";

const getScreenDimensions = () => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    return {
        height,
        width
    }
}

const useScreenDimensions = () => {
    const [screenDimensions, setScreenDimensions] = useState(getScreenDimensions());
    const [width, setWidth] = useState(getScreenDimensions().width);
    const [height, setHeight] = useState(getScreenDimensions().height);
    useEffect(() => {
        const resize = () => {
            setScreenDimensions(getScreenDimensions());
            setWidth(getScreenDimensions().width);
            setHeight(getScreenDimensions().height);
        }
        window.addEventListener('resize', resize);
        resize();
        return () => window.removeEventListener('resize', resize);
    }, [])
    return {
        screenDimensions, width, height
    }
}
export default useScreenDimensions;