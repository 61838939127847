import React, { useEffect, useState } from 'react';

const ProcessCard = ({ numeric, id, title, subtitle, text }) => {
  const [paragraphs, setParagraphs] = useState([]);
  const left = (id - 1) % 2 === 0;

  useEffect(() => {
    setParagraphs(text);
  }, [text]);
  return (
    <div
      className={
        'max-w-[700px] rounded-[32px] card-no-hover relative overflow-hidden' +
        (left ? ' self-start' : ' self-end')
      }
    >
      {numeric && (
        <div className="absolute top-0 left-0 py-3 px-6 bg-dg-primary-900 text-white leaf">
          {id}
        </div>
      )}
      <div className="flex flex-col justify-start text-left gap-8p">
        <h3 className="title-card">{title}</h3>
        <h4 className="subtitle-card-italic">{subtitle}</h4>
        {paragraphs &&
          paragraphs.map((item, index) => (
            <p className="paragraph" key={index}>
              {item.ParagraphElement}
            </p>
          ))}
      </div>
    </div>
  );
};
export default ProcessCard;
