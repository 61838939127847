import { motion } from 'framer-motion';
import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Animation_Diligent from '../assets/animation_diligent.webm';
import ActionCard from '../components/shared/ActionCard';
import GradientWrapper from '../components/shared/GradientWrapper';
import LandingSVGv2 from '../components/shared/graphics/LandingSVG-v2';
import useDataApi from '../hooks/useDataApi';
import PageLayout from '../layout/PageLayout';
import Wrapper from '../layout/Wrapper';
import WorkClientForm from './../components/WorkClientForm';
import useAnalytics from './../hooks/useAnalytics';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const mailchimp_url =
  'https://dilig.us18.list-manage.com/subscribe/post?u=4bd507e0bc2f58fc19f284648&amp;id=09da427d96&amp;f_id=00b927e7f0';

const download = {
  downloadFilePath: `${process.env.PUBLIC_URL}/DiligentCompanyOverview.pdf`,
  downloadFileName: 'Diligent Company Overview.pdf',
};

const downloadData = {
  button: 'Download',
  paragraph: 'Detailed preview of our company and how you can fit in.',
  title: 'Get our Company Overview!',
};

const strapiPopulate = [
  'Title',
  'Subtitle',
  'Title2',
  'Subtitle2',
  'WhyUs',
  'WhyUs.img',
  'WorkWithUs',
];

const StartupCorner = () => {
  const [formSuccess, setFormSuccess] = React.useState(false);
  const strapi = strapiApiBuilder('startup-corner', strapiPopulate, '');
  const [{ data, isLoading, isError }, doFetch] = useDataApi(strapi);

  useAnalytics('Startup Corner');

  React.useEffect(() => {
    document.title = 'Startup Corner';
  }, []);

  React.useEffect(() => {
    if (formSuccess) {
      window.open(download.downloadFilePath, '_blank');
    }
  }, [formSuccess]);

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else if (data) {
    return (
      <PageLayout>
        <Wrapper>
          <div className="flex flex-col md:flex-row gap-90p md:pb-32 mt-[120px] md:mt-[180px]">
            <motion.section
              id="landing"
              className="flex flex-col md:flex-row items-center justify-between"
              initial={{ y: 60, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
            >
              <div className="flex flex-col gap-8 text-center md:text-left w-full md:w-1/2">
                <h1 className="heading bg-gradient-to-r from-purple-500 to-purple-700 bg-clip-text text-transparent">
                  {data.Title}
                </h1>
                <p className="paragraph">{data.Subtitle}</p>
              </div>
              <div className="w-full md:w-1/2">
                <LandingSVGv2 />
              </div>
            </motion.section>
          </div>
        </Wrapper>
        <GradientWrapper padding={'py-32p md:py-90p'}>
          <h6 className="n-h3-heading text-white text-center">{data.Title2}</h6>
          <p className="n-paragraph text-white md:max-w-[800px] mx-0 md:mx-auto text-center">
            {data.Subtitle2}
          </p>
        </GradientWrapper>
        <Wrapper padding={'pb-[16px] md:py-[100px]'}>
          <div className="flex flex-col gap-[32px] md:gap-[120px]">
            {data.WhyUs.map((item, index) => (
              <div
                key={index}
                className={
                  (index % 2 === 0
                    ? 'flex flex-col md:flex-row'
                    : 'flex flex-col md:flex-row-reverse') +
                  ' w-full justify-between items-center gap-[24px] md:gap-0'
                }
              >
                <div className="w-full">
                  <img src={item.img.data.attributes.url} alt={item.title} />
                </div>
                <div className="flex flex-col gap-[8px] md:gap-[16px] max-w-[520px] text-center md:text-left">
                  <h6 className="n-h3-heading text-dark-gray">{item.title}</h6>
                  <p className="n-paragraph">{item.paragraph}</p>
                </div>
              </div>
            ))}
          </div>
        </Wrapper>
        <div className="md:mb-[50px]">
          <div className="w-full relative mt-12 z-[-1]">
            <div className="radial-gradient absolute top-0 left-0 right-0 bottom-0"></div>
            <div className="max-w-custom mx-auto">
              <img
                src="https://pub-66e80b135e8645b7b882369787a605d0.r2.dev/media-lib/test-bucket/israel_andrade_YI_9_Siv_Vt_s_unsplash_1_9698b8c35a.png"
                alt="Company Overview Office"
              />
            </div>
          </div>
          <MailchimpSubscribe
            url={mailchimp_url}
            render={({ subscribe, status, message }) => (
              <>
                <WorkClientForm
                  data={downloadData}
                  download={download}
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
                {status === 'success' ? setFormSuccess(true) : setFormSuccess(false)}
              </>
            )}
          />
        </div>
        <Wrapper padding={'py-90p'}>
          <ActionCard
            title={data.WorkWithUs[0].title}
            text={data.WorkWithUs[0].paragraph}
            btn2={data.WorkWithUs[0].ButtonSecondary}
            btn1={data.WorkWithUs[0].ButtonPrimary}
            link2={'/portfolio'}
            link1={'/contact'}
          />
        </Wrapper>
      </PageLayout>
    );
  } else {
    return <></>;
  }
};

export default StartupCorner;
